<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">AJUSTE MANUAL</v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="8" md="2">
                                <v-text-field class='text-caption' v-model="sku" label="SKU" :disabled="disabledSku" :filled="filledSku" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1"><v-btn @click="verSku(sku)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn></v-col>
                            <v-col cols="12" md="2">
                                <v-alert class='text-caption' v-if="existe=='si'" dense text type="success">
                                    Existe !
                                </v-alert>
                                <v-alert class='text-caption' v-else-if="existe=='no'" dense text type="warning">
                                    no Existe !
                                </v-alert>
                                <v-alert class='text-caption' v-else dense text type="info">
                                    <span class="text-caption grey--text">Sku: 99999</span>
                                </v-alert>
                            </v-col >
                            <v-col cols="12" md="4">
                                <v-text-field class='text-caption' v-model="nombre" label="Nombre Producto" disabled filled outlined dense ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field class='text-caption' v-model="categoria" label="Categoria" disabled filled outlined dense ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider class="grey"></v-divider>
                        <v-row class="mt-1">
                            <v-col cols="12" md="6">
                                <v-textarea rows="6" class='text-caption' no-resize outlined v-model="descripcion" label="Descripcion" disabled filled></v-textarea>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field class='text-caption' v-model="caracteristica" label="Caracteristica" outlined dense disabled filled></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field class='text-caption' v-model="medida" label="Medida" outlined dense disabled filled></v-text-field>
                                        <!-- <v-text-field class='text-caption' v-model="preciolocal" label="Precio" outlined dense disabled filled></v-text-field> -->
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6" class="mt-n6">
                                        <v-select class='text-caption' v-on:change="totalStock(bodega)" v-model="bodega" :items="listabodega" label="Bodega" item-text="nombre" item-value="id" :hint="tStock" persistent-hint dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>
                                    </v-col>
                                    <v-col cols="12" md="4" class="mt-n6">
                                        <v-text-field class='text-caption' @keypress="onlyDecimal" v-model="cantidad" label="Cantidad" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" class="mt-n6">
                                        <v-select class='text-caption' v-model="movimiento" :items="listamovimiento" label="I / E" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>
                                    </v-col>
                                    <v-col cols="12" md="8" class="mt-n6">
                                        <v-text-field class='text-caption' v-model="observa" label="Observacion" outlined dense :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions style="margin-top:-45px">
                <v-spacer></v-spacer>
                <v-btn @click="clsInput()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                <v-btn v-if="disabledDatos!=true" @click="saveStock()" class="ml-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                <v-btn v-else class="ml-2" small color="grey" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
            </v-card-actions>
        </v-card>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Manual',  
    components:{dialogo, snack}, 
    props: ['usu','contrato'], 
    data(){
        return{
            //contrato:'123',
            tStock:'',
            nStock:'',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            disabledBodega:false,
            filledBodega:false,
            disabledSku:false,
            filledSku:false,
            disabledDatos:true,
            filledDatos:true,
            existe:'',
            listabodega:[],
            sku:'',
            nombre:'',
            categoria:'',
            ncategoria:0,
            descripcion:'',
            caracteristica:'',
            cantidad:'',
            bodega:'',
            preciolocal:'',
            observa:'',
            dateHoy: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            listamovimiento:['Ingreso','Egreso'],
            movimiento:'',
            medida:'',
        }
    },
    mounted(){
        this.searchBodegas();
    },
    methods:{
        async searchBodegas(){
            try {
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/bodegas/'+ this.contrato);
                const datos=response.data;
                if(datos[0].existe=='si'){
                    datos.forEach(element => {
                        this.listabodega.push({'id':element.ID,'nombre':element.Nombre})
                    });                
                }
            } catch (error) {
                console.log(error)               
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        clsInput(){
            this.disabledBodega=false;
            this.filledBodega=false;
            this.disabledSku=false;
            this.filledSku=false;
            this.disabledDatos=true;
            this.filledDatos=true;
            this.existe='';
            this.sku='';
            this.nombre='';
            this.categoria='';
            this.ncategoria=0;
            this.descripcion='';
            this.caracteristica='';
            this.cantidad='';
            this.bodega='';
            this.preciolocal='';
            this.observa=''
            this.movimiento='';
            this.tStock='';
            this.nStock=0;
            this.medida='';
        },
        async verSku(xSku){
            this.loading=true;
            try {
                if(xSku.trim()!=''){
                    this.disabledSku=true;
                    this.filledSku=true;
                    const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/producto/'+this.contrato+'/'+xSku);
                    const datos=response.data;
                    //console.log(datos);
                    if (datos[0].existe=='si'){
                        if(datos[0].Estado=='V'){
                            this.existe='si';
                            this.sku=datos[0].Sku;
                            this.nombre=datos[0].Nombre;
                            this.ncategoria=datos[0].Categoria;
                            this.descripcion=datos[0].Descripcion;
                            this.caracteristica=datos[0].Caracteristica;
                            this.preciolocal=datos[0].PrecioLocal;
                            this.medida=datos[0].Medida;
                            await this.nombreCategoria(this.ncategoria)
                            this.restoDatos(false);
                        } else {
                            this.Msg='Producto no vigente'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                    } else {
                        this.Msg='SKU no Existe';
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                } else {
                    this.Msg='Ingrese SKU';
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }                
            } catch (error) {
                console.log(error)
            }
            this.loading=false;
        },
        async nombreCategoria(numeroCategoria){
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/categorias/'+ this.contrato);
            const datos=response.data;
            //console.log(datos);
            datos.forEach(element => {
                if(element.ID==numeroCategoria){
                    this.categoria=element.Nombre
                }
            });
        },
        async saveStock(){
            try {
                var correcto='si';
                if(!isNaN(this.cantidad)){
                    this.cantidad=this.cantidad;
                } else {
                    this.cantidad='';
                };
                if(this.bodega!='' && this.cantidad!='' && this.movimiento!=''){
                    if(this.movimiento=='Egreso' && parseFloat(this.cantidad)>parseFloat(this.nStock)){
                        correcto='no';
                    }
                    if(correcto=='si'){
                        if(parseFloat(this.cantidad)!=0){
                            var signo = '';
                            if(this.movimiento=='Ingreso'){signo='0+'};
                            if(this.movimiento=='Egreso'){signo='0-'};
                            var parametros={
                                'contrato': this.contrato,
                                'sku': this.sku,
                                'cantidad': this.cantidad,
                                'signo': signo,
                                'bodega': this.bodega,
                                'usuario':this.usu,
                                'observa':this.observa,
                                'origen':this.movimiento
                            };
                            //grabamos stock
                            const response = await axios.post(process.env.VUE_APP_URL + '/api/inv/stock',parametros);
                            const datos=response.data;
                            if (datos!='si'){
                                this.Msg='Inconsistencia al grabar Datos';
                                this.msgbox=true;
                                this.color='red';
                                this.showSnack();
                            } else {
                                this.Msg= this.movimiento + ' de Producto Exitoso';
                                this.msgbox=true;
                                this.color='success';
                                this.showSnack();
                                this.disabledDatos=true;
                            }
                        } else {
                            this.Msg= 'Cantidad 0';
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                            this.disabledDatos=true;
                        }
                    } else {
                        this.Msg='Revisar Stock';
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                } else {
                    this.Msg='Falta dato obligatorio'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }        
            } catch (error) {
                console.log(error)
            }
        },
        restoDatos(bool){
            this.disabledDatos=bool,
            this.filledDatos=bool
        },
        onlyNumber ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        onlyDecimal ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        async totalStock(id){
            this.nStock=0;
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/stock/bodegas/producto/total/'+ this.contrato +'/'+ id + '/' + this.sku);
            const datos=response.data;
            if (datos[0].existe=='si'){ 
                this.tStock='Total Stock:'+datos[0].Total;
                this.nStock=datos[0].Total;
            }
            if (datos[0].existe=='no'){ 
                this.tStock='Sin Stock';
            } 
            if (datos[0].existe=='error'){ 
                this.tStock='Error de Stock';
            } 
        }
    }
}
</script>