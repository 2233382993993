<template>
<div>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
        <v-card color="primary" dark>
        <v-card-text>
            Procesando ...
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
        </v-card>
    </v-dialog>
</div>    
</template>
<script>
export default {
    name:'dialogo',
    props:['loading']
}
</script>