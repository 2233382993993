<template>
  <div>
    <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">Acceso</h2> <!-- Full Screen -->
    <h3 class="mt-1 hidden-sm-and-up grey--text">Acceso</h3> <!-- Mobile Screen -->
    <div class="mt-5">
      <v-card>
        <v-card-text>
          <div class="mt-5 text-center">
            <h1 class="grey--text">Bienvenido a Bodega Net</h1>
            <h5 class="mt-2 grey--text">Sistema de inventario y ventas cloud</h5>
            <img src="@/assets/ticVerde.png" max-width="200" max-height="200"/>
          </div>
          <div class="mt-10 text-center">
            <h5 class="mt-2 grey--text">Fecha: {{hoy.toLocaleDateString()}}</h5>
            <h5 class="mt-2 grey--text">Usuario: {{usu}}</h5>
            <h5 class="mt-2 grey--text">Contrato: {{contrato}}</h5>
          </div>
        </v-card-text>
        <v-divider class="mt-5"></v-divider>
        <v-card-actions>
          <h6 class="mt-5 grey--text">Aplicacion Inventario </h6>
          <v-spacer></v-spacer>
          <h6 class="mt-5 grey--text">Todos los derechos reservados, Copyright © PwaStore Soluciones 2021 </h6>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
<script>
export default {
    name: 'Bienvenido',
    props:['usu','contrato'],
    data(){
      return{ hoy:new Date()}
    }
}
</script>
