<template>
    <div>
      <div class="mt-5 ml-5 mr-5">
          <v-row>
              <v-col cols="12" md="4">
                  <v-row>
                      <v-col cols="10" md="8" class="text-center">
                          <v-select class='text-caption'
                              prepend-icon="search" 
                              :items="ecommerces"
                              item-text='name' 
                              item-value='id'
                              v-model="id_ecommerce" 
                              label="ecommerce" 
                              dense outlined rounded @change="buscarOrdenes(id_ecommerce)">
                          </v-select>                    
                      </v-col>
                      <v-col cols="10" md="4" class="text-center">
                        <v-btn class="mt-2" @click="dialogEcommLocal=true" rounded x-small>
                            Local Asignado
                        </v-btn>
                      </v-col>
                      <v-col cols="12" md="12" class="mt-n10">
                          <v-data-table dark
                          height="350"
                          :headers="headers" 
                          :items="items_orders" 
                          hide-default-footer 
                          class="elevation-1 text-size"
                          :search="search"
                          :page.sync="page" 
                          :items-per-page="itemsPerPage"
                          @page-count="pageCount = $event"
                          >
                          <template v-slot:item.total="{ item }">
                              <span>{{new Intl.NumberFormat("de-DE").format(parseInt(item.total))}}</span> 
                          </template>
                          <template v-slot:item.detalle="{ item }">
                              <v-btn @click="detalleOrden(item)" icon x-small><v-icon color="warning" small>visibility</v-icon></v-btn>  
                          </template>
                          </v-data-table>
                          <v-pagination v-model="page" :length="pageCount" color="teal" total-visible="5"></v-pagination>
                      </v-col>
                  </v-row>
              </v-col>
              <v-col cols="12" md="8">
                  <v-row>
                      <v-col cols="6" md="6">
                          <span class="text-h6 indigo--text">Orden : {{txt_orden}}</span>
                      </v-col>
                      <v-col cols="6" md="6" class="text-end">
                          <span class="text-h6 indigo--text">Total : {{new Intl.NumberFormat("de-DE").format(parseInt(txt_total))}}</span>
                      </v-col>
                      <v-col cols="12" md="12" class="mt-n5">
                          <v-data-table fixed-header
                          height="150"
                          :headers="head_products"
                          :items="items_products"  
                          hide-default-footer 
                          class="elevation-1 text-size"
                          >
                          <template v-slot:item.sku="{ item }">
                              <span v-if="item.valsku=='si'">{{item.sku}}</span>
                              <span v-else class="error--text">{{item.sku}}</span>
                          </template>
                          <template v-slot:item.precio="{ item }">
                              <span>{{new Intl.NumberFormat("de-DE").format(parseInt(item.precio))}}</span> 
                          </template>
                          <template v-slot:item.stock="{ item }">
                              <v-icon v-if="item.stock=='si'" color="success" small>check_circle</v-icon>
                              <v-icon v-else color="error" small>cancel</v-icon>
                          </template>
                          </v-data-table>
                      </v-col>
                      <v-col cols="12" md="6" class="mt-n2">
                          <v-card>
                            <v-card-subtitle class="mb-n7">
                                <v-row>
                                <v-col cols="6">
                                    <h5>FACTURACIÓN</h5>
                                </v-col>
                                <v-divider vertical class="mt-1 my-8"></v-divider>
                                <v-col cols="6" class="text-center">
                                <v-select @change="cambiaRut(ntipoDte)" class='mt-n1 text-caption' v-model="ntipoDte" :items="itemDTE" label="" item-text="doc" item-value="id" rounded dense></v-select>
                                </v-col>
                            </v-row>
                            </v-card-subtitle>
                            <v-divider></v-divider>  
                            <v-card-text>
                                  <h5>Rut: <span class="teal--text">{{fac_rut}}</span></h5>
                                  <h5>Razon Social: <span class="teal--text">{{fac_razon}}</span></h5>
                                  <h5>Direccion: <span class="teal--text">{{fac_direccion}}</span></h5>
                                  <h5>Comuna: <span class="teal--text">{{fac_comuna}}</span></h5>
                                  <h5>Ciudad: <span class="teal--text">{{fac_ciudad}}</span></h5>
                                  <h5>Giro: <span class="teal--text">{{fac_giro}}</span></h5>
                                  <h5>Email: <span class="teal--text">{{fac_email}}</span></h5>
                              </v-card-text>
                              <v-card-actions class="mt-n2">
                                <v-row v-if="clienteExiste=='si'">
                                    <v-col cols="6">
                                        <v-btn color='grey' x-small rounded dark>Crear Cliente</v-btn>
                                    </v-col>
                                    <v-divider vertical class="my-3"></v-divider>
                                    <v-col cols="6" class="text-end">
                                        <h5>Cliente Existente</h5>
                                    </v-col>
                                </v-row>                                                        
                                <v-row v-if="clienteExiste=='no'">
                                    <v-col cols="6">
                                        <v-btn @click="dialogClients=true" color='teal' x-small rounded dark>Crear Cliente</v-btn>
                                    </v-col>
                                    <v-divider vertical class="my-3"></v-divider>
                                    <v-col cols="6" class="text-end">
                                        <h5>Cliente no Existe</h5>
                                    </v-col>
                                </v-row>                                                        
                              </v-card-actions>
                          </v-card>
                      </v-col>
                      <v-col cols="12" md="6" class="mt-n2">
                          <v-card>
                            <v-card-subtitle>
                                <h5>DESPACHO</h5>
                            </v-card-subtitle>
                            <v-divider class="mt-n2"></v-divider> 
                          <v-card-text>
                              <!-- <div class="mb-2 text-end">
                                  <v-btn @click="descargar_etiqueta(txt_orden)" color='teal' x-small rounded dark>Descargar Etiqueta</v-btn>
                              </div> -->
                              <h5>Direccion: <span class="teal--text">{{des_direccion}}</span></h5>
                              <h5>Comuna: <span class="teal--text">{{des_comuna}}</span></h5>
                              <h5>Ciudad: <span class="teal--text">{{des_ciudad}}</span></h5>
                              <h5>Pais: <span class="teal--text">{{des_pais}}</span></h5>
                              <h5>Cod. Postal: <span class="teal--text">{{des_postal}}</span></h5>
                              <h5>Email: <span class="teal--text">{{des_email}}</span></h5>
                          </v-card-text>
                          </v-card>
                          <v-row>
                            <v-col cols="12" md="12">
                                <div class="mt-5 text-end">
                                    <v-btn @click="nuevo()" class="mr-1" color="teal" rounded dark small>
                                        <v-icon left>add_circle</v-icon>
                                        <span class='text-btn'>Nuevo</span>
                                    </v-btn>
                                    <v-btn v-if="rutEcommerce!='' && items_products.length!=0" @click="integraVenta()" class="ml-1" color='error' rounded dark small>
                                        <v-icon left>storage</v-icon>
                                        <span class='text-btn'>Generar DTE</span>
                                    </v-btn>
                                    <v-btn v-else class="ml-1" color='grey' rounded dark small>
                                        <v-icon left>storage</v-icon>
                                        <span class='text-btn'>Generar DTE</span>
                                    </v-btn>
                                    <a  v-if="btnPrt===true" target="_blank" :href="html" class="ml-2"><v-icon color="teal">print</v-icon></a>
                                    <a  v-else href="javascript:void(0)" class="ml-3"><v-icon class="grey--text">print</v-icon></a>
                                </div>
                            </v-col>
                          </v-row>
                      </v-col>
                  </v-row>
              </v-col>
          </v-row>
      </div>
      <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
      <!-- dialogo carga -->
        <v-dialog v-model="dialogoCarga" hide-overlay persistent width="300">
            <v-card color="primary" dark>
            <v-card-text>
                <v-row class="mt-2">
                    <v-col cols="12" md="12">
                        <span>Procesando ...</span>
                        <v-progress-linear indeterminate color="white"></v-progress-linear>
                    </v-col>
                    <v-col cols="12" md="12" class="mt-n4 text-center">
                        <strong class="text-body-2 font-italic">{{numero}}%</strong>
                    </v-col>
                </v-row>
            </v-card-text>
            </v-card>
        </v-dialog>
        <!-- DIALOGO NEW LOCAL -->
        <v-dialog v-model="dialogEcommLocal" transition="dialog-bottom-transition" max-width="400" persistent>
            <v-card>
                <v-card-title>
                    Selecciona Local
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogEcommLocal=false">
                        <v-icon color="red">cancel</v-icon>
                    </v-btn>
                </v-card-title>
            <v-card-text class="mt-5">
                <v-row>
                    <v-col cols="1" md="1"></v-col>
                    <v-col cols="10" md="10">
                        <v-text-field class='text-caption' v-model="nameLocal" placeholder="Sin Local" outlined dense rounded filled disabled ></v-text-field>
                    </v-col>
                    <v-col cols="1" md="1"></v-col>
                </v-row>
                <v-row class="mt-n8">
                    <v-col cols="12" md="12">
                        <v-data-table @click:row="selecionaLocal" dense dark :headers="localHead" :items="localList" :page.sync="pageLocal" :items-per-page="itemsPerPageLocal"  @page-count="pageCountLocal = $event" hide-default-footer class="elevation-1 text-size">
                        </v-data-table>
                        <v-pagination v-model="pageLocal" :length="pageCountLocal" :total-visible="5"></v-pagination>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-container>
                    <v-btn @click="asignarLocal()" block small rounded color="indigo" dark>Cambiar Local</v-btn>
                </v-container>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- DIALOGO CLIENTS -->
        <v-dialog v-model="dialogClients" transition="dialog-bottom-transition" max-width="400" persistent>
            <v-card>
                <v-card-title>
                    <h5>CREAR CLIENTE</h5>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogClients=false">
                        <v-icon color="red">cancel</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-15">
                    <v-row>
                        <v-col cols="12" class="mt-n10">
                            <v-text-field class='text-caption' v-model="fac_rut" label="Rut" dense outlined rounded style="width:200px" filled disabled></v-text-field>
                        </v-col>
                        <v-col cols="12" class="mt-n5">
                            <v-text-field class='text-caption' v-model="fac_razon" label="Razon Social" dense outlined rounded filled disabled></v-text-field>
                        </v-col>
                        <v-col cols="12" class="mt-n10">
                            <v-text-field class='text-caption' v-model="fac_direccion" label="Dirección" dense outlined rounded filled disabled></v-text-field>
                        </v-col>
                        <v-col cols="12" class="mt-n10">
                            <v-text-field class='text-caption' v-model="fac_comuna" label="Comuna" dense outlined rounded filled disabled></v-text-field>
                        </v-col>
                        <v-col cols="12" class="mt-n10">
                            <v-text-field class='text-caption' v-model="fac_ciudad" label="Ciudad" dense outlined rounded filled disabled></v-text-field>
                        </v-col>
                        <v-col cols="12" class="mt-n10">
                            <v-text-field class='text-caption' v-model="fac_giro" label="Giro" dense outlined rounded filled disabled></v-text-field>
                        </v-col>
                        <v-col cols="12" class="mt-n10">
                            <v-text-field class='text-caption' v-model="fac_email" label="Email" dense outlined rounded filled disabled></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="mt-n10">
                    <v-container>
                        <v-btn @click="saveClients()" block small rounded color="teal" dark>Crear Cliente</v-btn>
                    </v-container>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <dialogo :loading="loading"  />
    </div>
  </template>
    <style>
      .text-size td {
          font-size: 8pt !important;
          height: 25px !important;
          /* background-color: lightyellow; */
      }
    </style>
    <script>
      import axios from "axios";
      import snack from '@/components/snack.vue';
      import dialogo from '@/components/dialogo.vue';
      export default {
        name: 'NewOrden',
        components:{snack,dialogo},
        props: ['usu','contrato'],
        data(){
         return{
          loading:false,
          msgbox:false,
          color:'',
          Msg:'',
          token:'',
          id_ecommerce:0,
          totalStock:0,
          ecommerces:[
            {id:0,name:'Selecciona ...'},
            // {id:90,name:'Mercado Libre'},
            // {id:91,name:'Dafiti'},
            // {id:92,name:'Falabella'},
            // {id:93,name:'Ripley'},
            // {id:94,name:'Paris'},
            // {id:95,name:'JumpSeller'},
            // {id:96,name:'Shopify'},
            {id:99,name:'Sito Web'},
          ],
          search:'',
          page: 1,
          pageCount: 0,
          itemsPerPage: 15,
  
          //datos orden
          txt_orden:'',
          txt_total:0,
          //datos facturacion
          fac_rut:'',
          fac_razon:'',
          fac_direccion:'',
          fac_comuna:'',
          fac_ciudad:'',
          fac_giro:'',
          fac_email:'',
          //datos Despacho
          des_direccion:'',
          des_comuna:'',
          des_ciudad:'',
          des_pais:'',
          des_postal:'',
          des_email:'',
  
          items_all:[],
  
          items_orders:[],
          headers:[
            { text: 'Fecha', align: 'center', sortable: true, value: 'fecha' },
            { text: 'Orden', align: 'start', sortable: true, value: 'orden' },
            { text: 'Total', align: 'start', sortable: true, value: 'total' },
            { text: 'Detalle', align: 'center', sortable: false, value: 'detalle' },
          ],
          items_products:[],
          head_products:[
            { text: 'Sku', align: 'center', sortable: true, value: 'sku' },
            { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
            { text: 'Variante', align: 'center', sortable: false, value: 'variante' },
            { text: 'Cantidad', align: 'center', sortable: true, value: 'cantidad' },
            { text: 'Precio', align: 'start', sortable: true, value: 'precio' },
            { text: 'Stock', align: 'center', sortable: false, value: 'stock' },
          ],
          items_clients:[],
          items_shippings:[],
          control_stock:[],
          //nuevo dialogo carga
          dialogoCarga:false,
          numero:0,
          //datos de la venta
          entidad:0,
          rutEcommerce:'1-9',
          clientelista:[],
          impuesto:19,
          totalNeto:0,
          totalIva:0,
          totalVenta:0,
          OutID:0,
          ntipoDte:22,  // solo boletas por ahora -- (22) Boleta ; (5) Factura 
          itemDTE:[
            {id:22,doc:'Boleta'},
            {id:5,doc:'Factura'},
          ],
          pago:10, // webpay por ahora
          hoy:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          vencimiento:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          html:'',
          rut:'',
          ciudad:'',
          razon:'',
          comuna:'',
          giro:'',
          email:'',
          direccion:'',
          bodega:0,

          tk_fisico:'',

          dialogEcommLocal:false,
          nameLocal:'',
          codeLocal:0,
          localHead:[
            { text: 'ID', align: 'center', sortable: false, value: 'code' },
            { text: 'Descripcion', align: 'start', sortable: false, value: 'name' },
            { text: '', align: 'center', sortable: false, value: 'accion' }
          ],
          localList:[],
          pageLocal: 1,
          pageCountLocal: 0,
          itemsPerPageLocal: 5,
          btnPrt:false,
          dialogClients:false,
          clienteExiste:'',
         }
        },
        async mounted(){
            await this.search_impuesto();
            this.tk_fisico=localStorage.getItem('token');
            if (this.tk_fisico!=null){
                await this.buscarBodegas();
                await this.buscarBodegaEcommerce();
            } else {
                this.$router.push({name:'Login'})
            }
        },
        methods:{
          async search_impuesto(){
            try {
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/impuesto/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.impuesto=parseFloat(datos[0].Iva);
                }
            } catch (error) {
                console.log(error)
            }
          },
          showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
          },
          async cambiaRut(n){
            if(n==22){
                this.ntipoDte=n; 
                this.rutEcommerce='1-9';
                this.clienteExiste='';
            };
            if(n==5){
                this.ntipoDte=n;
                this.rutEcommerce=this.fac_rut;
                if(this.rutEcommerce!=''){
                    this.clienteExiste = await this.existeRut(this.rutEcommerce);
                } else {
                    this.Msg='Sin datos para Facturar'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            };
          },
          formatRut(rut){
            let rutCompleto=rut;
            rutCompleto=rutCompleto.replaceAll('.','');
            rutCompleto=rutCompleto.replaceAll(',','');
            rutCompleto=rutCompleto.replaceAll('-','');
            let numeros=rutCompleto.substring(0,rutCompleto.length-1);
            let digito=rutCompleto.slice(-1);
            let ruts=numeros+'-'+digito;
            return ruts;
          },
          async existeRut(rut){
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/clientes/'+this.contrato+'/'+ rut);
            const datos=response.data;
            if (datos[0].existe=='si'){
                return 'si'
            } else {
                return 'no'
            }
          },
          async saveClients(){
            if(this.fac_rut=='' || this.fac_razon=='' || this.fac_direccion=='' || this.fac_comuna=='' || this.fac_ciudad=='' || this.fac_giro=='' || this.fac_email==''){
                this.Msg='Todos los datos son obligatorios'
                this.msgbox=true;
                this.color='warning';
                this.showSnack();
            } else {
                var parametros={
                    'contrato': this.contrato,
                    'rut': this.fac_rut,
                    'nombre': this.fac_razon,
                    'direccion': this.fac_direccion,
                    'ciudad': this.fac_ciudad,
                    'comuna': this.fac_comuna,
                    'telefono': '',
                    'email': this.fac_email,
                    'observacion': this.fac_giro,
                    'estado': 'V',
                    'credito':'NO'    
                };
                const response = await axios.post(process.env.VUE_APP_URL + '/api/inv/clientes/nuevo',parametros);
                const datos=response.data;
                if (datos=='si'){ 
                    this.existe='si';               
                    this.Msg='Nuevo Cliente Existoso !!!'
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                    this.clienteExiste = await this.existeRut(this.fac_rut);
                } 
            }
          },
          async buscarBodegas(){
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/bodegas/'+this.contrato);
            const datos=response.data;
            this.localList=[];            
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.localList.push({"code":element.ID,"name":element.Nombre});
                });
            }
          },
          async buscarBodegaEcommerce(){
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/v1/local/portals/'+this.contrato);
            const datos=response.data;
            if (datos[0].existe=='si'){                
                this.bodega=datos[0].Bodega;
                const found = this.localList.filter( res => parseInt(res.code)==parseInt(this.bodega) )
                if(found.length!=0){
                    this.nameLocal=found[0].name;
                    this.codeLocal=found[0].code
                }
            }
          },
          selecionaLocal(item){
            this.nameLocal=item.name;
            this.codeLocal=item.code
          },
          async asignarLocal(){
            try {
                if(parseInt(this.codeLocal)!=0){
                    const params={
                        'contrato': this.contrato,
                        'local':this.codeLocal,
                    };
                    const response = await axios.put(process.env.VUE_APP_URL + '/api/inv/v1/local/portals/',params);
                    const res = response.data;
                    console.log(res);
                    this.Msg='Local Asignado'
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                    this.bodega=this.codeLocal;             
                } else {
                    this.Msg='Seleccione Local'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();                  
                }
            } catch (error) {
                console.log(error)
            }
          },
          async nuevo(){
            this.clsInput();
            this.id_ecommerce=0;
          },
          async clsInput(){
            this.numero=0;
            //datos orden
            this.txt_orden='';
            this.txt_total=0;
            //datos facturacion
            this.fac_rut='';
            this.fac_razon='';
            this.fac_direccion='';
            this.fac_comuna='';
            this.fac_ciudad='';
            //datos Despacho
            this.des_direccion='';
            this.des_comuna='';
            this.des_ciudad='';
            this.des_pais='';
            this.des_postal='';
            this.des_email='';
            this.items_all=[];  
            this.items_orders=[];
            this.items_products=[];
            this.items_clients=[];
            this.items_shippings=[];
            this.control_stock=[];
            this.btnPrt=false;
            this.html='';
            this.clienteExiste='';
          },
          async buscarOrdenes(portal){ 
            this.clsInput();
            if(portal!=0){
                this.dialogoCarga=true;
                try {
                    let arrayFinal=[];
                    let arrayOrders=[];
                    let arrayProducts=[];
                    let arrayClients=[];
                    let arrayShipping=[];

                    let response;
                    let datos;
                    let params;
                    let sub_response;
                    let sub_datos;
                    let sub_params;

                    params = {
                        "portal":portal,
                        "contrato":this.contrato,
                        "user":this.usu
                    };
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.tk_fisico
                    };
                    //buscar ordenes NO integradas
                    response = await axios.post(process.env.VUE_APP_URL1 + '/api/v1/orders/portal/',params,{headers});
                    datos=response.data;
                    if (datos[0].existe=='si'){

                        //calcular barra de progreso
                        const rango=100/parseFloat(datos.length);
                        const tope=parseFloat(datos.length);
                        var itera=0;
                        this.numero=0;

                        for (let index = 0; index < datos.length; index++) {
                            const element = datos[index];
                            //orden
                            arrayOrders.push({
                                'id':element.ID,
                                'fecha':element.Fecha,
                                'orden':element.Orden,
                                'total':element.Total
                            });
                            //productos
                            sub_params = {
                                "orden":element.Orden,
                                "portal":portal,
                                "contrato":this.contrato,
                                "user":this.usu
                            };
                            sub_response = await axios.post(process.env.VUE_APP_URL1 + '/api/v1/order/products/',sub_params,{headers});
                            sub_datos=sub_response.data;
                            if (sub_datos[0].existe=='si'){
                                for (let i = 0; i < sub_datos.length; i++) {
                                    const products = sub_datos[i];
                                    // validamos la exisencia del sku
                                    const existeSku = await this.buscarSku(products.Sku);
                                    // validamos el stock del sku existente
                                    let txtStock = '';
                                    //let quantySku = products.Cantidad; //aca debemos buscar la cantidad del ecommerce
                                    if (existeSku!='no'){
                                        // const stockSku = await this.stockSku(this.bodega,products.Sku,products.Cantidad);
                                        // if(stockSku <= 0){
                                        //     txtStock='no'
                                        // } else { 
                                        //     txtStock='si';
                                        // }
                                        txtStock='si';
                                    } else { 
                                        txtStock='no' 
                                    }
                                    arrayProducts.push({
                                        'orden':products.Orden,
                                        'sku':products.Sku,
                                        'valsku':existeSku,
                                        'bodega':this.bodega,
                                        'producto':products.Producto,
                                        'variante':products.Variante,
                                        'cantidad': products.Cantidad,
                                        'precio':products.Precio,
                                        'stock':txtStock
                                    })
                                }

                            }
                            //despacho
                            sub_response = await axios.post(process.env.VUE_APP_URL1 + '/api/v1/order/shipping/',sub_params,{headers});
                            sub_datos=sub_response.data;
                            if (sub_datos[0].existe=='si'){
                                const shipping=sub_datos[0];
                                arrayShipping.push({
                                    'orden':shipping.Orden,
                                    'Nombre':shipping.Nombre,
                                    'direccion':shipping.Direccion,
                                    'comuna':shipping.Comuna,
                                    'ciudad':shipping.Ciudad,
                                    'pais':shipping.Pais,
                                    'postal':shipping.Codigo_Postal,
                                    'email':shipping.Email
                                })
                            }
                            //Facturacion 
                            sub_response = await axios.post(process.env.VUE_APP_URL1 + '/api/v1/order/invoice/',sub_params,{headers});
                            sub_datos=sub_response.data;
                            if (sub_datos[0].existe=='si'){
                                const clients=sub_datos[0];
                                arrayClients.push({
                                    'orden':clients.Orden,
                                    'rut':this.formatRut(clients.Rut),
                                    'razon':clients.Razon,
                                    'direccion':clients.Direccion,
                                    'comuna':clients.Comuna,
                                    'ciudad':clients.Ciudad,
                                    'giro':clients.Giro,
                                    'email':clients.Email,
                                })
                            }
                            //iterando el proceso
                            if(parseFloat(tope)!=(index+1)){
                                itera=parseFloat(itera)+parseFloat(rango);
                                this.numero=parseFloat(itera).toFixed(0);
                            } else {
                                this.numero=100;
                            }                        
                        }
                        //cargar array final
                        arrayFinal.push({
                            id_ecomerce:portal,
                            orden:arrayOrders,
                            productos:arrayProducts,
                            cliente:arrayClients,
                            despacho:arrayShipping
                        })
                        this.items_all=arrayFinal;
                        this.items_orders=arrayOrders;
                    } else {
                        console.log('sin datos')
                    }              
                } catch (error) {
                    console.log(error)
                }
                setTimeout(() => {this.dialogoCarga=false}, 1000);
            }
          },
          async buscarSku(sku){
              try {
                  const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/producto/'+this.contrato+'/'+sku);
                  const datos=response.data;
                  if (datos[0].existe!='si'){ 
                      return 'no' 
                  } else { 
                      return 'si' 
                  }
              } catch (error) {
                  return 'no'
              }
          },
          async stockSku(bodega,sku,cantidad){
              let total_stock = 0;
              let sigue = 'no';
              if(this.control_stock.length!=0){
                  //buscamos si existe sku
                  for (let index = 0; index < this.control_stock.length; index++) {
                      const element = this.control_stock[index];
                      if (element.sku==sku){
                          total_stock=parseInt(this.control_stock[index].stock);
                          total_stock=total_stock-parseInt(cantidad);
                          this.control_stock[index].stock=total_stock
                          sigue = 'no';
                          break;
                      } else {
                          sigue = 'si'
                      } 
                  }
              } else {
                  sigue='si'
              }
              if(sigue=='si'){
                  //consumir api
                  try {
                      const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/stock/bodegas/producto/total/'+this.contrato+'/'+bodega+'/'+sku);
                      const datos=response.data;
                      if (datos[0].existe=='si'){
                          this.control_stock.push({
                              'sku' : sku,
                              'stock' : datos[0].Total
                          }) 
                          total_stock=datos[0].Total
                      } 
                      if (datos[0].existe=='no'){ total_stock=0 } 
                      if (datos[0].existe=='error'){ total_stock=0 }                 
                  } catch (error) { total_stock=0 }
                  this.totalStock=total_stock;
              }
              return total_stock
          },
          async detalleOrden(item){
            this.txt_orden=item.orden;
            this.txt_total=item.total;
            
            this.items_products=[];
            this.items_clients=[];
            this.items_shippings=[];

            //datos facturacion
            this.fac_rut='';
            this.fac_razon='';
            this.fac_direccion='';
            this.fac_comuna='';
            this.fac_ciudad='';
            this.fac_giro='';
            this.fac_email='';
            //datos Despacho
            this.des_direccion='';
            this.des_comuna='';
            this.des_ciudad='';
            this.des_pais='';
            this.des_postal='';
            this.des_email='';

            await this.cambiaRut(22); // x defecto
  
            let registros;
            for (let i = 0; i < this.items_all.length; i++) {
              const element_all = this.items_all[i];
              //productos de la orden
              registros=element_all.productos;
              for (let ii = 0; ii < registros.length; ii++) {
                const element = registros[ii];
                if(parseFloat(element.orden)==parseFloat(item.orden)){
                  let imp=1+(this.impuesto/100);
                  let unidad=parseFloat(element.precio)/imp;
                  this.items_products.push({
                    'sku':element.sku,
                    'producto':element.producto,
                    'variante':element.variante,
                    'cantidad': element.cantidad,
                    'precio':element.precio,
                    'unidad':unidad,
                    'valsku':element.valsku,
                    'bodega':element.bodega,
                    'stock':element.stock
                  })
                }              
              }
              //facturacion de la orden
              registros=element_all.cliente;
              for (let ii = 0; ii < registros.length; ii++) {
                const element = registros[ii];
                if(parseFloat(element.orden)==parseFloat(item.orden)){
                  this.items_clients=element;
                  this.fac_rut=element.rut;
                  this.fac_razon=element.razon;
                  this.fac_direccion=element.direccion;
                  this.fac_comuna=element.comuna;
                  this.fac_ciudad=element.ciudad;
                  this.fac_giro=element.giro;
                  this.fac_email=element.email;
                }
              }
              if(this.items_clients.length!=0){ await this.cambiaRut(5) }
              //despacho de la orden
              registros=element_all.despacho;
              for (let ii = 0; ii < registros.length; ii++) {
                const element = registros[ii];
                if(parseFloat(element.orden)==parseFloat(item.orden)){
                  this.items_shippings=element;
                  this.des_direccion=element.direccion;
                  this.des_comuna=element.comuna;
                  this.des_ciudad=element.ciudad;
                  this.des_pais='Chile';
                  this.des_postal=element.postal;
                  this.des_email=element.email;
                }
              }
            }
            if(this.fac_rut!=''){
                this.clienteExiste = await this.existeRut(this.fac_rut);
            }
          },

        //*********************************************** Crear Venta *************************************************************/

        async Totales(){
            const imp=1+(this.impuesto/100);
            this.totalVenta=parseFloat(this.txt_total); //2000
            this.totalNeto=parseFloat(this.totalVenta/imp).toFixed(2); //1680,67
            this.totalIva=parseFloat((this.totalNeto*this.impuesto)/100).toFixed(2); //319.33
            console.log(this.impuesto,this.totalNeto,this.totalIva,this.totalVenta)
        },
        async buscaCliente(){
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/lista/clientes/'+this.contrato);
            const datos=response.data;
            this.clientelista=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    if(element.Rut==this.rutEcommerce){ 
                        this.entidad = element.ID;
                        this.rut = element.Rut;
                        this.ciudad = element.Ciudad;
                        this.razon=element.Nombre;
                        this.comuna = element.Comuna;
                        this.giro = element.Observacion;
                        this.email = element.Email;
                        this.direccion = element.Direccion
                    };
                });
            }
        },
        async integraVenta(){
            this.loading=true;
            try {
                await this.buscaCliente();
                await this.Totales();
                const stateCotiza = await this.saveCotiza();
                if(stateCotiza){
                    const Order_Integrada='I';
                    await this.stateOrders(Order_Integrada);
                    var sigla=this.contrato.substring(0,3);
                    if (sigla=='INV') {
                        await this.inDTE();
                    } else {
                        if (sigla=='NDV'){
                            await this.outDTE();
                        } else {
                            this.Msg='Error de Contrato'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                    }
                    await this.clearOrders();                 
                } else {
                    this.Msg='Inconsistencia en datos'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } catch (error) {
                console.log("integraVenta: "+error)
            }
            this.loading=false;
        },
        async saveCotiza(){
            let todos='si';
            if(this.items_products.length!=0){
                for (let index = 0; index < this.items_products.length; index++) {
                    const element = this.items_products[index];
                    var parametros={
                        'contrato': this.contrato,
                        'orden': this.txt_orden,
                        'entidad': this.entidad,
                        'tipo': 'Neto',
                        'cv': 'V',
                        'sku':element.sku,
                        'producto':element.producto,
                        'precio':element.precio,
                        'cantidad':element.cantidad,
                        'bodega': element.bodega, 
                        'observa':'',
                        'usuario':this.usu,
                        'referencia':'0'};
                    const response = await axios.post(process.env.VUE_APP_URL + '/api/inv/compraventa/nueva',parametros);
                    const datos=response.data;
                    //console.log(datos);
                    if (datos!='si'){ 
                        todos='no'
                    }
                }
                if(todos!='si'){
                    // eliminar cotiza
                    const response = await axios.delete(process.env.VUE_APP_URL + '/api/inv/orden/delete/'+this.contrato+'/'+this.txt_orden);
                    const datos=response.data;
                    return false;
                } else {
                    console.log('Cotizacion Grabada');
                    return true
                }
            } else {
                return false
            }
        },
        async stateOrders(state){
            try {
                const params={
                    'contrato': this.contrato,
                    'orden': this.txt_orden,
                    'portal': this.id_ecommerce,
                    'estado':state,
                    'user':this.usu
                };
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.tk_fisico
                };
                const response = await axios.put(process.env.VUE_APP_URL1 + '/api/v1/order/state/',params,{headers});
                const res = response.data;
                console.log(res)
            } catch (error) {
                console.log("stateOrders: "+error)
            }
        },
        async clearOrders(){
            let arrayOrders=[];
            for (let index = 0; index < this.items_orders.length; index++) {
                const element = this.items_orders[index];
                if(element.orden!=this.txt_orden){
                    arrayOrders.push({
                        'id':element.id,
                        'fecha':element.fecha,
                        'orden':element.orden,
                        'total':element.total
                    });
                }
            };
            this.items_orders=[];
            this.items_orders=arrayOrders;
            this.txt_orden='';
            this.txt_total=0;
            
            this.items_products=[];
            this.items_clients=[];
            this.items_shippings=[];

            //datos facturacion
            this.fac_rut='';
            this.fac_razon='';
            this.fac_direccion='';
            this.fac_comuna='';
            this.fac_ciudad='';
            //datos Despacho
            this.des_direccion='';
            this.des_comuna='';
            this.des_ciudad='';
            this.des_pais='';
            this.des_postal='';
            this.des_email='';
        },
        async outDTE(){
            try {
                const selectDTE=this.ntipoDte;
                const selectPago=this.pago;
                if (parseFloat(this.totalVenta)!=0){
                    if (selectDTE!=0 && selectPago!=0){
                        this.OutID=0;
                        //obtener ID nuevo del dte
                        const params={
                            'contrato':this.contrato,
                            'orden':this.txt_orden,
                            'estado':'V',
                            'usuario':this.usu
                        };
                        try {
                            const response = await axios.post(process.env.VUE_APP_URL + '/api/inv/DTE/id/new/v2/',params);
                            const res = response.data;
                            if (res=='si'){
                                const response1 = await axios.get(process.env.VUE_APP_URL + '/api/inv/DTE/id/new/v2/'+this.contrato+'/'+this.txt_orden+'/'+this.usu);
                                const res1 = response1.data;
                                if (res1[0].existe=='si'){
                                    this.OutID=parseFloat(res1[0].Folio); 
                                }                            
                            };
                            if (this.OutID!=0){
                                //confirmar que no existe en DTE
                                const response2 = await axios.get(process.env.VUE_APP_URL + '/api/inv/DTE/one/'+this.contrato+'/'+this.OutID);
                                const res2 = response2.data;
                                if (res2[0].existe=='no'){ 
                                    this.html=process.env.VUE_APP_URL + '/api/inv/prt/venta/v80/'+this.contrato+'/'+this.OutID+'/'+this.entidad;
                                    const paramsDTE={
                                        'contrato':this.contrato,
                                        'numero':this.OutID,
                                        'tipoDte':selectDTE,
                                        'tipoPago':selectPago,
                                        'neto':this.totalNeto,
                                        'iva':this.totalIva,
                                        'total':this.totalVenta,
                                        'fecha':this.hoy,
                                        'vencimiento':this.vencimiento,
                                        'orden':this.txt_orden,
                                        'rutaPDF':this.html,
                                        'rutaXML':this.html,
                                        'usuario':this.usu,
                                        'sucursal':this.bodega
                                    };     
                                    await this.baseDTE(paramsDTE);
                                } else {
                                    this.Msg='Folio '+ this.OutID +' ya existe'
                                    this.msgbox=true;
                                    this.color='red';
                                    this.showSnack();
                                }
                            } else {
                                this.Msg='Folio No Asignado'
                                this.msgbox=true;
                                this.color='red';
                                this.showSnack();
                            }
                        } catch (error) {
                            console.log(error)
                        }
                    } else {
                        this.Msg='Seleccione DTE y Pago'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                } else {
                    this.Msg='Sin Productos'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }                
            } catch (error) {
                console.log("OutDTE: "+error)
            }
        }, 
        async inDTE(){
            try {
                const selectDTE=this.ntipoDte;
                const selectPago=this.pago;
                if (selectDTE!=0 && selectPago!=0){                
                    // obtenemos el token del contrato
                    let valToken='';
                    const resToken = await axios.get(process.env.VUE_APP_URL + '/api/inv/token/access/'+this.contrato);
                    const respuesta = resToken.data;
                    if (respuesta[0].existe=='si'){ 
                        valToken=respuesta[0].Token;
                    } else { console.log('Token Invalido') }
                    // fecha cotizacion formato Bsale
                    let intFecha=Date.parse(this.hoy);
                    let strFecha=intFecha.toString();
                    strFecha=strFecha.substring(0,10);
                    const numFecha=parseFloat(strFecha);
                    // fecha actual formato Bsale
                    let intHoy=Date.parse(this.hoy);
                    let strHoy=intHoy.toString();
                    strHoy=strHoy.substring(0,10);
                    const numHoy=parseFloat(strHoy);
                    // fecha vencimiento formato Bsale
                    let intVence=Date.parse(this.vencimiento);
                    let strVence=intVence.toString();
                    strVence=strVence.substring(0,10);
                    const numVence=parseFloat(strVence);
                    // creamos json Bsale

                    let pCliente={
                        "code": this.rut,
                        "city": this.ciudad,
                        "company": this.razon,
                        "municipality": this.comuna,
                        "activity": this.giro,
                        "address": this.direccion
                    };
                    let pDetalle=[];
                    this.items_products.forEach(element => {
                        pDetalle.push({
                            //"variantId":1,
                            //"netUnitValue":parseFloat(element.precio).toFixed(4),
                            "netUnitValue":parseFloat(element.unidad).toFixed(4),
                            "quantity": parseFloat(element.cantidad).toFixed(4),
                            //"taxId": "[1,2]",
                            "comment": element.producto,
                            //"discount": 0,
                            "taxes": [
                                {
                                "code": 14,
                                "percentage": 19
                                }
                            ]
                        })        
                    });
                    let pPago=[
                        {
                            "paymentTypeId": selectPago,
                            "amount": this.totalVenta,
                            "recordDate": numHoy
                        }
                    ];
                    let pReferencia=[
                        {
                            "number": this.txt_orden,
                            "referenceDate": numFecha,
                            "reason": "Orden de Compra "+this.txt_orden,
                            "codeSii": 801
                        }
                    ];
                    //console.log(selectDTE);
                    var emitDTE;
                    if(selectDTE!=22){ // factura
                        emitDTE=
                        {
                            "documentTypeId": selectDTE,
                            "officeId": 1,
                            "emissionDate": numHoy,
                            "expirationDate": numVence,
                            "declareSii": 1,
                            //"priceListId": 18,
                            "client": pCliente,
                            "details": pDetalle,
                            "payments": pPago,
                            "references": pReferencia
                        }
                    } else { // boleta
                        emitDTE=
                        {
                            "documentTypeId": selectDTE,
                            //"documentTypeId": 1,
                            "officeId": 1,
                            "emissionDate": numHoy,
                            "expirationDate": numHoy,
                            "declareSii": 1,
                            //"priceListId": 18,
                            "details": pDetalle
                            //"payments": pPago,
                        }
                    }
                    // consumir api Bsale para crear DTE
                    let resDTE =[];
                    //console.log(valToken);
                    //console.log(emitDTE);
                    try {
                        const response = await axios.post('https://api.bsale.cl/v1/documents.json', emitDTE , {headers : {"Accept":"application/json","content-type":"application/json","access_token":valToken}});
                        resDTE = response.data;
                        //console.log(resDTE);
                    } catch (error) {
                        console.log (error);
                    }
                    if (resDTE.length!=0){
                        //this.envioDTE(this.envio,resDTE.urlPdf,resDTE.urlXml);
                        this.html=resDTE.urlPdf;
                        //this.btnGrabar=false;
                        this.OutID=0;
                        this.OutID=resDTE.number;                    
                        const paramsDTE={
                            'contrato':this.contrato,
                            'numero':resDTE.number,
                            'tipoDte':selectDTE,
                            'tipoPago':selectPago,
                            'neto':this.totalNeto,
                            'iva':this.totalIva,
                            'total':this.totalVenta,
                            'fecha':this.hoy,
                            'vencimiento':this.vencimiento,
                            'orden':this.txt_orden,
                            'rutaPDF':resDTE.urlPdf,
                            'rutaXML':resDTE.urlXml,
                            'usuario':this.usu,
                            'sucursal':this.bodega
                        };                    
                        await this.baseDTE(paramsDTE);
                    } else {
                        this.Msg='DTE no enviado'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                    //console.log(res);
                } else {
                    this.Msg='Seleccione DTE y Pago'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
                
            } catch (error) {
                console.log("inDTE: "+error)
            }
        },
        async baseDTE(parametrosDTE){
            try {
                const response = await axios.post(process.env.VUE_APP_URL + '/api/inv/DTE/nueva',parametrosDTE);
                const datos=response.data;
                //console.log(parametrosDTE);
                if (datos=='si'){ 
                    await this.updateReferencia();
                } else {
                    this.Msg='DTE enviado sin registro Local'
                    this.msgbox=true;
                    this.color='warning';
                    this.showSnack();
                }                
            } catch (error) {
                console.log("baseDTE: "+error)
            }
        },
        async updateReferencia(){
            try {
                var parametros0={'numero': this.OutID};
                const response0 = await axios.put(process.env.VUE_APP_URL + '/api/inv/referencia/actualiza/'+this.contrato+'/'+this.txt_orden+'/'+this.entidad,parametros0);
                const datos0=response0.data;
                //console.log(datos0);
                if (datos0=='si'){
                    //await this.UpdateStock();
                    this.Msg='DTE exitoso';
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                    this.btnPrt=true;
                } else {
                    this.Msg='DTE enviado sin Referencia';
                    this.msgbox=true;
                    this.color='warning';
                    this.showSnack();
                }                
            } catch (error) {
                console.log("updateReferencia: "+error)
            }
        },

        // en este modulo no se actualiza stock

        async UpdateStock(){
            try {
                let t=0;
                if (this.items_products.length!=0) {
                    for (var i = 0; i < this.items_products.length; i++) {
                        const element=this.items_products[i];
                        var parametros1={
                                'contrato': this.contrato,
                                'sku': element.sku,
                                'cantidad': element.cantidad,
                                'signo': '0-',
                                'bodega': this.bodega,
                                'usuario': this.usu,
                                'observa': '',
                                'origen':'Venta'};
                        const response1 = await axios.post(process.env.VUE_APP_URL + '/api/inv/stock',parametros1);
                        const datos1=response1.data;
                        if (datos1=='si'){ t++ }
                    };
                    if (t!=this.items_products.length) {
                        this.Msg='DTE enviado sin actualizar Stock';
                        this.msgbox=true;
                        this.color='warning';
                        this.showSnack();
                    } else {
                        this.Msg='DTE exitoso';
                        this.msgbox=true;
                        this.color='success';
                        this.showSnack();
                    }
                }                
            } catch (error) {
                console.log("UpdateStock: "+error)
            }
        },

         //fin Methods
        }
      }
    </script>
  