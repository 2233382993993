<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">
                <v-row>
                    <v-col cols="6" md="6">PAGOS</v-col>
                    <v-col cols="6" md="6">
                        <v-row class="mt-0 mr-2" justify="end">
                            <span class="text-caption"><v-chip color="white"><strong class="text-body-2">{{txtSucursal}}</strong></v-chip></span>
                            <!-- <span class="text-caption">Suc.:<strong class="text-body-2 ml-2">{{txtSucursal}}</strong></span> -->
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="3" class="mt-n3">
                                <v-radio-group v-model="docDTE" row>
                                    <v-radio @click="newVenta()" label="Boleta" color="success" value="22"></v-radio>
                                    <v-radio @click="newVenta()" label="Factura" color="success" value="5"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="8" md="2">
                                <v-text-field class='text-caption' v-model="orden" label="Numero Venta" :disabled="disabledOrden" :filled="filledOrden" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1">
                                <v-btn @click="verDTE(orden)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field class='text-caption' v-model="rut" label="Rut Cliente" dense outlined disabled filled></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field class='text-caption' v-model="razon" label="Nombre" dense outlined disabled filled></v-text-field>
                            </v-col>
                            <!-- <v-col cols="12" md="2">
                                <v-text-field class='text-caption' v-model="ndev" label="Orden" dense outlined filled disabled></v-text-field>
                            </v-col> -->
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="9">
                                <v-row class="form-space">
                                    <v-col cols="12" md="4">
                                        <v-select class='text-caption' v-model="pago" :items="itemPAGO" label="Forma Pago" item-text="forma" dense outlined></v-select>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field class='text-caption' v-model="referencia" label="Referencia" dense outlined></v-text-field>
                                    </v-col>
                                    <v-col cols="9" md="2">
                                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="monto" label="Monto" dense outlined></v-text-field>
                                    </v-col>
                                    <v-col cols="3" md="2">
                                        <v-btn @click="addPagos()" small fab dark color="indigo"><v-icon>playlist_add</v-icon></v-btn>
                                    </v-col>
                                    <v-col cols="12" md="12" class="form-space">
                                        <v-data-table :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size col-color" @page-count="pageCount = $event">
                                            <template v-slot:item.accion="{ item }">
                                                <v-icon small class="red--text" @click="deleteItem(item)">delete</v-icon>
                                                <!-- <v-icon v-if="confirma!='V'" small class="red--text" @click="deleteItem(item)">delete</v-icon>
                                                <v-icon v-else small color="grey">do_not_disturb</v-icon> -->
                                            </template>
                                        </v-data-table>
                                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="3" class="text-space">
                                <v-alert v-if="parcial==total && parcial!=0" class='text-caption' dense text border="left" color="green">Total ($): {{parcial.toLocaleString()}}</v-alert>
                                <v-alert v-else class='text-caption' dense text border="left" color="red">Total ($): {{parcial.toLocaleString()}}</v-alert>
                                <v-alert class='text-caption' dense text border="left" color="grey">Afecto ($):{{neto.toLocaleString()}}</v-alert> 
                                <v-alert class='text-caption' dense text border="left" color="grey">Iva ({{impuesto}}%): {{iva}}</v-alert>
                                <v-alert class='text-caption' dense text border="left" color="green">Total ($): {{total.toLocaleString()}}</v-alert>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12" class="text-space">
                                <div class="text-right">
                                    <v-btn @click="newVenta()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                                    <v-btn v-if="parcial==total && parcial!=0 && confirma=='V'" @click="Pagar()" class="ml-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Confirmar</span></v-btn>
                                    <v-btn v-else class="ml-2" small color="grey" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Confirmar</span></v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
            </v-card-text>
        </v-card>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 25px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
.form-space {
    margin-top:-35px;
}
.text-space {
    margin-top:-25px;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Pagos', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            //contrato:'123',
            nSucursal:0,
            txtSucursal:'',

            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            
            disabledOrden:false,
            filledOrden:false,

            orden:'',
            ndev:'0',
            entidad:'',
            rut:'',
            razon:'',

            neto:0,
            impuesto:'',
            iva:0,
            total:0,
            parcial:0,

            pago:'',
            referencia:'',
            monto:'',

            page: 1,
            pageCount: 0,
            itemsPerPage: 3,
            headers: [
                { text: 'Fecha', align: 'start', sortable: true, value: 'fecha' },
                { text: 'Forma Pago', align: 'start', sortable: true, value: 'pago' },
                { text: 'Referencia', align: 'start', sortable: false, value: 'referencia' },
                { text: 'Monto', align: 'center', sortable: false, value: 'monto' },
                { text: '', align: 'center', sortable: false, value: 'accion' }
            ],
            items: [],
            itemPAGO:[
                {id:'1',forma:'Efectivo'},
                {id:'2',forma:'T.Credito'},
                {id:'3',forma:'T.Debito'},
                {id:'4',forma:'Transferencia'},
                {id:'5',forma:'Cheque'},
                {id:'6',forma:'Credito Cta.Cte.'},
                {id:'7',forma:'Nota de Credito'}
            ],
            
            pordescuento:0,
            subtotal:0,
            fecharef:'',
            ids:1,
            confirma:'',
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            docDTE:'5',
            idLocal:0,
        }
    },
    mounted(){
        this.newVenta();
        this.buscaLocal();
        //this.buscarSucursal()
    },
    methods:{
        async buscaLocal(){
            try {
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/usuarios/'+this.contrato+'/'+this.usu);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.idLocal=datos[0].Bodega;
                    this.nSucursal=this.idLocal;
                    const sucu=await this.buscarSucursal_v2();
                    if(sucu!=''){
                        this.txtSucursal=sucu;
                    } else {
                        this.txtSucursal=this.nSucursal;
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async buscarSucursal_v2(){
            let res = '';
            try {
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/bodegas/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    datos.forEach(element => {
                        if(element.ID==this.nSucursal){
                            res = element.Nombre
                        };
                    });
                }                
            } catch (error) {
                console.log(error)
            };
            return res
        },
        // async buscarSucursal(){
        //     const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/usuarios/'+this.contrato+'/'+this.usu);
        //     const datos=response.data;
        //     if (datos[0].existe=='si'){
        //         if (datos[0].Sucursal!=0){
        //             this.nSucursal=datos[0].Sucursal;
        //             const response1 = await axios.get(process.env.VUE_APP_URL + '/api/inv/sucursales/'+this.contrato);
        //             const datos1=response1.data;
        //             if (datos1[0].existe=='si'){
        //                 datos1.forEach(element => {
        //                     if(element.ID==this.nSucursal){
        //                         this.txtSucursal=element.Nombre                            
        //                     }
        //                 });
        //             } else {  
        //                 this.txtSucursal='Sin Sucursal';
        //                 this.nSucursal=0;
        //             }
        //         } else {
        //             this.txtSucursal='Sin Sucursal';
        //             this.nSucursal=0;
        //         }
        //     } else { 
        //         this.txtSucursal='Sin Sucursal';
        //         this.nSucursal=0;
        //     }
        // },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        onlyNumber ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        clsInput(){
            this.ndev='0';
            this.rut='';
            this.razon='';
            this.entidad='';
            this.pago='';
            this.referencia='',
            this.monto='',
            this.neto=0;
            this.iva=0;
            this.total=0;
            this.parcial=0;
            this.impuesto='';
            this.items=[];
            this.pordescuento=0;
            this.subtotal=0;
            this.ids=1;
            this.confirma='';
        },
        newVenta(){
            this.orden='';
            this.disabledOrden=false;
            this.filledOrden=false;
            this.clsInput()
        },
        async verDTE(nOrden){
            if (this.orden!=''){
                this.clsInput();
                this.loading=true;
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/DTE/dev/'+this.contrato+'/'+nOrden+'/'+this.docDTE);
                const datos=response.data;
                console.log(datos);
                if (datos[0].existe=='si'){
                    this.disabledOrden=true;
                    this.filledOrden=true;
                    this.ndev = datos[0].Orden;
                    this.entidad=datos[0].Entidad;
                    this.rut=datos[0].Rut;
                    this.razon=datos[0].Nombre;
                    this.impuesto=datos[0].Iva;
                    //this.pordescuento=datos[0].Descuento;
                    this.items=[];
                    let nTotal=0;
                    datos.forEach(element => {
                        nTotal=nTotal+parseFloat(element.Total)       
                    });
                    let imp=1+(this.impuesto/100);
                    let varNeto=nTotal/imp;
                    let varIva=(varNeto*this.impuesto)/100;

                    //this.subtotal=varNeto;
                    let descuento=Math.round((nTotal*parseFloat(this.pordescuento))/100);            
                    let afecto=nTotal-descuento;
                    //let nIva=Math.round((afecto*parseFloat(datos[0].Iva))/100);
                    this.neto=parseFloat(varNeto).toFixed(2);
                    this.iva=parseFloat(varIva).toFixed(2);
                    this.total=nTotal;

                    const response1 = await axios.get(process.env.VUE_APP_URL + '/api/inv/pago/buscar/'+this.contrato+'/'+nOrden+'/'+this.docDTE);
                    const datos1=response1.data;
                    if (datos1[0].existe=='si'){
                        this.confirma=datos1[0].Estado;
                        datos1.forEach(element => {
                            this.items.push({
                                'id':this.ids,
                                'fecha':element.Fecha,
                                'pago': element.FormaPago,
                                'referencia': element.Referencia,
                                'monto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Monto)),
                                'valor':element.Monto
                            });
                            this.ids++;
                            this.parcial=this.parcial+parseFloat(element.Monto)
                        });
                    }
                } else {
                    this.Msg='DTE no existe'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
                this.loading=false;
            }
        },
        isNum(val){
            return !isNaN(val)
        },
        addPagos(){
            if(this.pago!=''){
                if (this.referencia.trim()!=''){
                    if (this.isNum(this.monto)){
                        const totalRojo=this.parcial+parseFloat(this.monto);
                        if (totalRojo<=parseFloat(this.total)){
                            this.parcial=totalRojo;
                            this.items.push({
                                'id':this.ids,
                                'fecha':this.date1,
                                'pago': this.pago,
                                'referencia': this.referencia,
                                'monto':new Intl.NumberFormat("de-DE").format(parseFloat(this.monto)),
                                'valor':this.monto
                            });
                            this.ids++;
                            this.pago='';
                            this.referencia='';
                            this.monto=''
                            if (this.parcial==parseFloat(this.total)){ this.confirma='V' }
                        }
                    } else {
                        this.Msg='Ingrese Monto'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                } else {
                    this.Msg='Ingrese Referencia'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } else {
                this.Msg='Ingrese Forma Pago'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        // addPagos(){
        //     if(this.pago!=''){
        //         if (this.referencia.trim()!=''){
        //             if (this.isNum(this.monto)){
        //                 if (this.parcial==parseFloat(this.total)){
        //                     this.Msg='Totales Iguales'
        //                     this.msgbox=true;
        //                     this.color='info';
        //                     this.showSnack();
        //                 } else {
        //                     if ((this.parcial+parseFloat(this.monto))<=parseFloat(this.total)){
        //                         this.items.push({
        //                             'id':this.ids,
        //                             'fecha':this.date1,
        //                             'pago': this.pago,
        //                             'referencia': this.referencia,
        //                             'monto':new Intl.NumberFormat("de-DE").format(parseFloat(this.monto)),
        //                             'valor':this.monto
        //                         });
        //                         this.ids++;
        //                         this.parcial=this.parcial+parseFloat(this.monto);
        //                         this.pago='';
        //                         this.referencia='';
        //                         this.monto=''
        //                     } else {
        //                         this.Msg='Igualar Totales'
        //                         this.msgbox=true;
        //                         this.color='red';
        //                         this.showSnack();
        //                     }
        //                 }
        //             } else {
        //                 this.Msg='Ingrese Monto'
        //                 this.msgbox=true;
        //                 this.color='red';
        //                 this.showSnack();
        //             }
        //         } else {
        //             this.Msg='Ingrese Referencia'
        //             this.msgbox=true;
        //             this.color='red';
        //             this.showSnack();
        //         }
        //     } else {
        //         this.Msg='Ingrese Forma Pago'
        //         this.msgbox=true;
        //         this.color='red';
        //         this.showSnack();
        //     }
        // },
        deleteItem(array){
            this.items = this.items.filter(function(dat) {
                return dat.id !== array.id; 
            });
            this.parcial=this.parcial-parseFloat(array.valor)
        },
        async Pagar(){
            this.loading=true;
            //eliminar registros de pago
            const response0 = await axios.delete(process.env.VUE_APP_URL + '/api/inv/pago/delete/'+this.contrato+'/'+this.orden);
            const datos0=response0.data;
            if (datos0!='error'){ 
                let params;
                let reg=0;
                for (let index = 0; index < this.items.length; index++) {
                    const element =this.items[index];
                    params={
                        'fecha':element.fecha,
                        'contrato':this.contrato,
                        'numero': this.orden,
                        'tipo':this.docDTE,
                        'entidad': this.entidad,
                        'forma':element.pago,
                        'referencia':element.referencia,
                        'monto':element.valor,
                        'sucursal':this.nSucursal,
                        //'usuario':this.usu,
                        'estado':'V'
                    }
                    const response = await axios.post(process.env.VUE_APP_URL + '/api/inv/pago/nuevo',params);
                    const datos=response.data;
                    if (datos=='si'){ 
                        reg++;
                    }                
                };
                if (reg==this.items.length){
                    this.Msg='Confirmacion Exitosa'
                    this.msgbox=true;
                    this.color='green';
                    this.showSnack();
                } else {
                    this.Msg='Confirmacion Fallida'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();              
                }
            }
            this.loading=false    
        },
    }
}
</script>