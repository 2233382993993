<template>
    <div>
        <v-card>
            <v-card-title class="blue-grey white--text">PAGO PROVEEDOR</v-card-title>
            <v-card-text>
                <v-row  class="mt-1">
                    <v-col cols="12" md="6">
                        <v-row class="mt-5">
                            <v-col cols="7" md="4" class="mt-n5">
                                <v-text-field class='text-caption' v-model="rut" label="Rut Cliente" outlined dense filled disabled></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="mt-n5">
                                <v-text-field class='text-caption' v-model="razon" label="Razon Social" outlined dense filled disabled></v-text-field>
                            </v-col>
                            <v-col cols="2" md="2" class="mt-n5">
                                <v-btn @click="clientes=true" small color="indigo" dark fab><v-icon>list</v-icon></v-btn>
                            </v-col>
                            <!-- <v-col cols="2" md="1" class="mt-n5 text-center">
                                <v-btn @click="search_creditos(rut)" small color="teal" dark fab><v-icon small>search</v-icon></v-btn>
                            </v-col> -->
                            <v-col cols="12" md="12" class="mt-n8">
                                <v-data-table :loading="pagosprogress"  loading-text="Cargando ..."  height="150px" dark dense :headers="header" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                                    <template v-slot:item.accion="{ item }">
                                        <v-icon class="ml-2" v-if="item.estado!='V'" @click="ck_push(item)" color='green' :disabled="disabled">check_box_outline_blank</v-icon>
                                        <v-icon class="ml-2" v-else @click="ck_push(item)" color='green' :disabled="disabled">check_box</v-icon>
                                    </template>
                                </v-data-table>
                            </v-col>
                            <v-col cols="12" md="6" class="mt-n5">
                                <v-pagination v-model="page" :length="pageCount"></v-pagination>
                            </v-col>
                            <v-col cols="12" md="6" class="mt-n5">
                                <v-alert class='text-body-2' text border="left" color="success">
                                    <strong>Total a Pagar:
                                        <div class="float-right">
                                            ${{new Intl.NumberFormat("de-DE").format(parseFloat(totalPagar))}}
                                        </div>
                                    </strong>
                                </v-alert>                                
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-row class="mt-5">
                            <v-col cols="12" md="4" class="mt-n5">
                                <v-select class='text-caption' v-model="pago" :items="itemPAGO" label="Forma Pago" item-text="forma" dense outlined :disabled="disabled"></v-select>
                            </v-col>
                            <v-col cols="12" md="4" class="mt-n5">
                                <v-text-field class='text-caption' v-model="referencia" label="Referencia" dense outlined :disabled="disabled"></v-text-field>
                            </v-col>
                            <v-col cols="9" md="3" class="mt-n5">
                                <v-text-field class='text-caption' @keypress="onlyNumber" v-model="monto" label="Monto" dense outlined :disabled="disabled"></v-text-field>
                            </v-col>
                            <v-col cols="3" md="1" class="mt-n5">
                                <div v-if="disabled!=true">
                                    <v-btn @click="addPagos()" small fab dark color="indigo"><v-icon>playlist_add</v-icon></v-btn>
                                </div>
                                <div v-else>
                                    <v-btn small fab dark color="grey"><v-icon>playlist_add</v-icon></v-btn>
                                </div>
                            </v-col>
                            <v-col cols="12" md="12" class="mt-n8">
                                <v-data-table height="150px" dense :headers="header_pago" :items="items_pago" :page.sync="pago_page" :items-per-page="pago_itemsPerPage" hide-default-footer class="elevation-1 text-size col-color" @page-count="pago_pageCount = $event">
                                    <!-- <template v-slot:item.accion="{ item }">
                                        <v-icon small class="red--text" @click="deleteItem(item)">delete</v-icon>
                                    </template> -->
                                </v-data-table>
                            </v-col>
                            <v-col cols="12" md="6" class="mt-n5">
                                <v-pagination v-model="pago_page" :length="pago_pageCount"></v-pagination>
                            </v-col>
                            <v-col cols="12" md="6" class="mt-n5">
                                <v-alert class='text-body-2' text border="left" color="indigo">
                                    <strong>Total Recaudación:
                                        <div class="float-right">
                                            ${{new Intl.NumberFormat("de-DE").format(parseFloat(totalRecauda))}}
                                        </div>
                                    </strong>
                                </v-alert>                                
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions style="margin-top:-30px">
                <v-checkbox @click="ck_all()" v-model="ckall" color="success" label="Pagar Todo" :disabled="disabled"></v-checkbox>
                <v-btn class="ml-10" @click="estadoCuentaPDF()" rounded color="teal" dark x-small>&nbsp;&nbsp;&nbsp;&nbsp;Estado de Cuenta&nbsp;&nbsp;&nbsp;&nbsp;</v-btn>
                <v-btn class="ml-10" @click="pagoCuentaPDF()" rounded color="teal" dark x-small>&nbsp;&nbsp;&nbsp;&nbsp;Historial de Pago&nbsp;&nbsp;&nbsp;&nbsp;</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="mr-2" @click="Nuevo()" rounded color="teal" dark>&nbsp;&nbsp;&nbsp;&nbsp;NUEVO&nbsp;&nbsp;&nbsp;&nbsp;</v-btn>
                <div v-if="btn==true">
                    <v-btn class="mr-2" @click="Pagar()" rounded color="teal" dark>&nbsp;&nbsp;&nbsp;&nbsp;PAGAR&nbsp;&nbsp;&nbsp;&nbsp;</v-btn>
                </div>
                <div v-else>
                    <v-btn class="mr-2" rounded color="grey" dark>&nbsp;&nbsp;&nbsp;&nbsp;PAGAR&nbsp;&nbsp;&nbsp;&nbsp;</v-btn>
                </div>
            </v-card-actions>
        </v-card>
        <!-- dialog clientes --> 
        <v-dialog v-model="clientes" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="clientes=false" color="red" small dark><v-icon small>close</v-icon></v-btn>
                <v-spacer>
                    <div class="float-right">
                    <v-text-field prepend-inner-icon="search" class='ml-5 mr-2 text-caption' v-model="clientesearch" label="Buscar" dense required></v-text-field>
                    </div>
                </v-spacer>
            </v-card-title>
            <v-card-text>
                <v-data-table  :loading="clienteprogress"  loading-text="Cargando ..."  @click:row="handleClientes" dense dark :search="clientesearch" :headers="clientetitulo" :items="clientelista" :page.sync="clientepage" :items-per-page="clienteitemsPerPage"  @page-count="clientepageCount = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="clientepage" :length="clientepageCount" :total-visible="5"></v-pagination>
            </v-card-text>
        </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 8pt !important;
    height: 25px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import axios from 'axios';
import { jsPDF } from "jspdf";
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
export default {
    name:'PagoProveedor',
    props: ['usu','contrato'],
    components:{dialogo, snack},
    data(){
        return{
            cuenta:'Pagar',
            disabled:false,
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',

            rut:'',
            razon:'',
            pago:'',
            referencia:'',
            monto:'',
            itemPAGO:[
                {id:'1',forma:'Efectivo'},
                {id:'2',forma:'T.Credito'},
                {id:'3',forma:'T.Debito'},
                {id:'4',forma:'Transferencia'},
                {id:'5',forma:'Cheque'}
            ],
            page: 1,
            pageCount: 0,
            itemsPerPage: 4,
            header: [
                { text: 'Vencimiento', align: 'start', sortable: false, value: 'vencimiento' },
                { text: 'Documento', align: 'start', sortable: false, value: 'documento' },
                { text: 'Numero', align: 'start', sortable: true, value: 'numero' },
                { text: 'Total', align: 'start', sortable: true, value: 'total' },
                { text: 'Pagar', align: 'start', sortable: false, value: 'accion' }
            ],
            items: [],
            pagosprogress:false,
            id_pago:0,
            header_pago: [
                { text: 'Forma Pago', align: 'start', sortable: true, value: 'pago' },
                { text: 'Referencia', align: 'start', sortable: false, value: 'referencia' },
                { text: 'Monto', align: 'center', sortable: false, value: 'monto' },
                { text: '', align: 'center', sortable: false, value: 'accion' }
            ],
            items_pago: [],
            pago_page: 1,
            pago_pageCount: 0,
            pago_itemsPerPage: 4,
            inTotal:0,
            totalPagar:0,
            totalRecauda:0,
            btn:false,
            clientes:false,
            clientesearch:'',
            clientetitulo:[
                { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                { text: 'Cliente', align: 'start', sortable: true, value: 'nombre' },
            ],
            clientelista:[],
            clientepage: 1,
            clientepageCount: 0,
            clienteitemsPerPage: 10,
            clienteprogress:false,
            ckall:false,
            folio:0,
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            proveedor_pagos:[],

        }
    },
    mounted(){
        this.search_entidad();
    },
    methods:{
        clsInput(){
            this.rut='';
            this.razon='';
            this.items=[];
            this.id_pago=0;
            this.items_pago=[];
            this.inTotal=0;
            this.totalPagar=0;
            this.totalRecauda=0;
            this.ckall=false;
            this.folio=0;
            this.btn=false;
            this.disabled=false;
            this.proveedor_pagos=[];
        },
        Nuevo(){
            this.clsInput();
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        async search_entidad(){
            this.clienteprogress=true;
            try {
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/proveedores/'+this.contrato);
                const datos=response.data;
                this.clientelista=[];
                if (datos[0].existe=='si'){
                    datos.forEach(element => {
                        this.clientelista.push({
                            entidad:element.ID,
                            nombre:element.Razon,
                            rut:element.Rut,
                            email:element.Email,
                            direccion:element.Direccion,
                            comuna:element.Comuna,
                            ciudad:element.Ciudad,
                            giro:element.Observacion,
                        })        
                    });
                }                
            } catch (error) {
                console.log(error)
            };
            this.clienteprogress=false;
        },
        handleClientes(fila) {
            this.clsInput();
            this.search_creditos(fila.entidad);
            this.search_pagos(fila.entidad);
            this.rut=fila.rut;
            this.razon=fila.nombre;
            this.clientes=false;
        },
        isNum(val){
            return !isNaN(val)
        },
        addPagos(){
            if(this.pago!=''){
                if (this.referencia.trim()!=''){
                    if (this.isNum(this.monto)){
                        if(parseFloat(this.monto)!=0){
                            this.inTotal=this.inTotal+parseFloat(this.monto);
                            if (parseFloat(this.inTotal)<=parseFloat(this.totalPagar)){
                                this.totalRecauda=this.inTotal;                        
                                this.id_pago++;
                                this.items_pago.push({
                                    'id':this.id_pago,
                                    'pago': this.pago,
                                    'referencia': this.referencia,
                                    'monto':new Intl.NumberFormat("de-DE").format(parseFloat(this.monto)),
                                    'valor':this.monto
                                });
                                if (parseFloat(this.totalRecauda)==parseFloat(this.totalPagar)){ this.btn=true } 
                                else { this.btn=false }
                            } else {
                                this.inTotal=this.inTotal-parseFloat(this.monto);
                            };
                            this.pago='';
                            this.referencia='';
                            this.monto='';
                        }
                    } 
                } 
            } 
        },
        async search_creditos(ente){
            this.pagosprogress=true;
            try {
                if(ente!=''){
                    const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/credito/proveedores/one/'+this.contrato +'/'+ente);
                    const datos=response.data;
                    console.log(datos);
                    this.items=[];
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            if(element.Numero!=null){
                                this.items.push({
                                    'entidad':ente,
                                    'fecha':element.Fecha,
                                    'vencimiento':element.Vencimiento,
                                    'tipodte':0,
                                    'documento':'Compra',
                                    'numero':element.Numero,
                                    'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                    'monto':element.Total,
                                    'estado':'N'
                                })        
                            }
                        });
                    }
                }                
            } catch (error) {
                console.log(error)
            };
            this.pagosprogress=false;
        },
        async search_pagos(ente){
            this.pagosprogress=true;
            const fecha_desde = '2024-01-01';
            const fecha_hasta = this.date1;
            this.proveedor_pagos=[];
            try {
                if(ente!=''){
                    const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/credito/pago/proveedor/'+this.contrato +'/'+ente+'/'+fecha_desde+'/'+fecha_hasta);
                    const datos=response.data;
                    if (datos[0].existe=='si'){
                        datos.forEach(element => {
                            this.proveedor_pagos.push({
                                'entidad':ente,
                                'fecha':element.Fecha,
                                'folio':element.Folio,
                                'formapago':element.FormaPago,
                                'referencia':element.Referencia,
                                'monto':element.Monto,
                                'usuario':element.Usuario,
                                'cuenta':element.Cuenta
                            })        
                        });
                    }
                }
            } catch (error) {
                console.log(error)
            };
            this.pagosprogress=false;
        },
        ck_push(fila){
            if(fila.estado=='N'){
                fila.estado='V';
                this.totalPagar=parseFloat(this.totalPagar)+parseFloat(fila.monto);
                if (parseFloat(this.totalRecauda)==parseFloat(this.totalPagar) 
                    && parseFloat(this.totalRecauda)!=0 
                    && parseFloat(this.totalPagar)!=0){ this.btn=true } 
                else { this.btn=false }
            } else { 
                fila.estado='N';
                this.ckall=false;
                this.totalPagar=parseFloat(this.totalPagar)-parseFloat(fila.monto);
                if (parseFloat(this.totalRecauda)==parseFloat(this.totalPagar) 
                    && parseFloat(this.totalRecauda)!=0 
                    && parseFloat(this.totalPagar)!=0){ this.btn=true } 
                else { this.btn=false }
            }
        },
        ck_all(){
            if(this.items.length!=0){
                this.totalPagar=0;
                if(this.ckall==true){
                    this.items.forEach(element => {
                        element.estado='V';
                        this.totalPagar=parseFloat(this.totalPagar)+parseFloat(element.monto);
                        if (parseFloat(this.totalRecauda)==parseFloat(this.totalPagar) 
                            && parseFloat(this.totalRecauda)!=0 
                            && parseFloat(this.totalPagar)!=0){ this.btn=true } 
                        else { this.btn=false }
                    });
                } else {
                    this.items.forEach(element => {
                        element.estado='N';
                        if (parseFloat(this.totalRecauda)==parseFloat(this.totalPagar) 
                            && parseFloat(this.totalRecauda)!=0 
                            && parseFloat(this.totalPagar)!=0){ this.btn=true } 
                        else { this.btn=false }
                    });
                }
            }
        },
        async Pagar(){
            this.loading=true;
            if(this.totalPagar>0){
                try {
                    this.pago='';
                    this.referenciav
                    this.monto='';
                    this.btn=false;
                    this.folio = Date.now() // time stamo
                    if(parseFloat(this.folio)!=0){
                        var reg = 0;
                        var sigue='no';
                        //grabar dte
                        for (let index = 0; index < this.items.length; index++) {
                            const element = this.items[index];
                            if(element.estado=='V'){
                                const params={
                                    'contrato':this.contrato,
                                    'fecha':this.date1,
                                    'folio':this.folio,
                                    'rut': this.rut,
                                    'razon':this.razon,
                                    'entidad':element.entidad,
                                    'tipodte': element.tipodte,
                                    'documento':element.documento,
                                    'numero':element.numero,
                                    'vencimiento':element.vencimiento,
                                    'monto':element.monto,
                                    'usuario':this.usu,
                                    'cuenta':this.cuenta,
                                }
                                const response = await axios.post(process.env.VUE_APP_URL + '/api/inv/credito/pago/nuevo/',params);
                                const datos=response.data;
                                if (datos=='si'){ 
                                    reg++;
                                }                
                            }
                        };
                        // comprobar la grabacion de todos los dte
                        const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/credito/clientes/search/folio/'+this.contrato+'/'+this.folio+'/'+this.cuenta);
                        const datos=response.data;
                        if (datos[0].existe=='si'){
                            if(datos.length==reg){
                                sigue='si';
                            } else {
                                //no grabo todo lo seleccionado por ende se borran registros
                                const res=await axios.delete(process.env.VUE_APP_URL + '/api/inv/credito/clientes/delete/entidad/'+this.contrato+'/'+this.folio+'/'+this.cuenta);
                                const dat=res.data;
                            }
                        } else {
                                //no encontro registros con el folio creado o bien no lee la base de datos, se borran regisros de todas formas 
                                const res=await axios.delete(process.env.VUE_APP_URL + '/api/inv/credito/clientes/delete/entidad/'+this.contrato+'/'+this.folio+'/'+this.cuenta);
                                const dat=res.data;
                        }
                        // grabar formas de pago siempre y cuando la grabacion de documentos este OK
                        if(sigue=='si'){
                            //grabar pagos
                            reg=0;
                            sigue='no';
                            for (let index = 0; index < this.items_pago.length; index++) {
                                const element = this.items_pago[index];
                                const params={
                                    'contrato':this.contrato,
                                    'fecha':this.date1,
                                    'folio':this.folio,
                                    'formapago': element.pago,
                                    'referencia':element.referencia,
                                    'monto':element.valor,
                                    'usuario':this.usu,
                                    'cuenta':this.cuenta,
                                }
                                const response = await axios.post(process.env.VUE_APP_URL + '/api/inv/credito/formapago/nuevo/',params);
                                const datos=response.data;
                                if (datos=='si'){ 
                                    reg++;
                                }                
                            };
                            // comprobar la grabacion de todas las formas de pagos
                            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/credito/clientes/search/pagos/'+this.contrato+'/'+this.folio+'/'+this.cuenta);
                            const datos=response.data;
                            if (datos[0].existe=='si'){
                                if(datos.length==reg){
                                    sigue='si';
                                } else {
                                    //no grabo todo lo ingresado por ende se borran registros de ambas tablas
                                    const res=await axios.delete(process.env.VUE_APP_URL + '/api/inv/credito/clientes/delete/entidad/'+this.contrato+'/'+this.folio+'/'+this.cuenta);
                                    const dat=res.data;
                                    const res1=await axios.delete(process.env.VUE_APP_URL + '/api/inv/credito/clientes/delete/pagos/'+this.contrato+'/'+this.folio+'/'+this.cuenta);
                                    const dat1=res1.data;
                                }
                            } else { 
                                //no encontro registros con el folio creado o bien no lee la base de datos, se borran regisros de todas formas 
                                const res=await axios.delete(process.env.VUE_APP_URL + '/api/inv/credito/clientes/delete/entidad/'+this.contrato+'/'+this.folio+'/'+this.cuenta);
                                const dat=res.data;
                                const res1=await axios.delete(process.env.VUE_APP_URL + '/api/inv/credito/clientes/delete/pagos/'+this.contrato+'/'+this.folio+'/'+this.cuenta);
                                const dat1=res1.data;
                            }
                        }
                        if(sigue=='si'){
                            this.Msg='Pago Exitoso'
                            this.msgbox=true;
                            this.color='success';
                            this.showSnack();
                            this.disabled=true;
                        } else {
                            this.Msg='Algo salio mal, intentelo nuevamente'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                    } else {
                        this.Msg='Folio no creado, intentelo nuevamente'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    };
                } catch (error) {
                    console.log(error)
                };
            } else {
                this.Msg='Monto no corresponde'
                this.msgbox=true;
                this.color='warning';
                this.showSnack();
            }
            this.loading=false;
        },
        async estadoCuentaPDF(){
            if(this.items.length!=0){
                let doc = new jsPDF('p', 'mm', 'letter');
                const date_1 = this.date1.split("-");
                const documentDate = date_1[2]+'-'+date_1[1]+'-'+date_1[0];
                doc.setFont("arial", "bold");
                doc.setFontSize(15);
                doc.text('ESTADO DE CUENTA' , 75, 20);


                doc.setFont("arial", "normal");
                doc.setFontSize(10);
                // doc.text('FOLIO: ' +  this.folio, 5, 35);
                doc.text('FECHA:' , 5, 40);
                doc.text('RUT:', 5, 45);
                doc.text('RAZON SOCIAL:', 5, 50);

                doc.setFont("arial", "bold");
                doc.text(documentDate , 35, 40);
                doc.text(this.rut, 35, 45);
                doc.text(this.razon, 35, 50);

                doc.setFont("arial", "normal");
                doc.line(3, 54, 209, 54);
                doc.text('FECHA DOC.', 10, 58);
                doc.text('TIPO DOC.', 45, 58);
                doc.text('NUMERO DOC.', 80, 58);
                doc.text('MONTO', 120, 58);
                doc.text('FECHA PAGO', 150, 58);
                doc.text('ESTADO', 185, 58);
                doc.line(3, 60, 209, 60);
                doc.setFontSize(9);
                var fila = 67;
                var saldo_pendiente = 0;
                var saldo_vencido = 0;
                var saldo_total = 0;
                this.items.forEach(element => {
                    var status='';
                    if(Date(element.vencimiento)<Date(this.date1)){
                        status='Pendiente';
                        saldo_pendiente = parseFloat(saldo_pendiente) + parseFloat(element.monto);
                        saldo_total = parseFloat(saldo_total) + parseFloat(element.monto);
                    } else {
                        status='Vencido';
                        saldo_vencido = parseFloat(saldo_vencido) + parseFloat(element.monto);
                        saldo_total = parseFloat(saldo_total) + parseFloat(element.monto);
                    };
                    const date_doc = element.fecha.split("-");
                    const fecha_doc = date_doc[2]+'-'+date_doc[1]+'-'+date_doc[0];
                    const tipo_doc = element.documento;
                    const numero_doc = element.numero;
                    const monto_doc = element.monto;
                    const date_vence = element.vencimiento.split("-");
                    const vence_doc = date_vence[2]+'-'+date_vence[1]+'-'+date_vence[0];
                    doc.text(fecha_doc.toString(), 10, fila);
                    doc.text(tipo_doc.toString(), 45, fila);
                    doc.text(numero_doc.toString(), 80, fila);
                    doc.text('$ ' + new Intl.NumberFormat("de-DE").format(parseFloat(monto_doc)), 120, fila);
                    doc.text(vence_doc.toString(), 150, fila);
                    doc.text(status.toString(), 185, fila);
                    fila = fila + 5;
                });
                fila = fila + 2;
                doc.line(3, fila, 209, fila);
                fila = fila + 5;
                doc.setFont("arial", "bold");
                doc.setFontSize(10);
                doc.text('TOTAL PENDIENTE: $ ' + new Intl.NumberFormat("de-DE").format(parseFloat(saldo_pendiente)), 10, fila);
                doc.text('TOTAL VENCIDO: $ ' + new Intl.NumberFormat("de-DE").format(parseFloat(saldo_vencido)), 85, fila);
                doc.text('CUENTA TOTAL: $ ' + new Intl.NumberFormat("de-DE").format(parseFloat(saldo_total)), 160, fila);
                fila = fila + 3;
                doc.line(3, fila, 209, fila);

                //para visualizar
                doc.output('dataurlnewwindow');
                //para descargar
                //doc.save("a4.pdf");
            }
        },
        async pagoCuentaPDF(){
            if(this.proveedor_pagos.length!=0){
                let doc = new jsPDF('p', 'mm', 'letter');
                doc.setFont("arial", "bold");
                doc.setFontSize(15);
                doc.text('HISTORIAL DE PAGO' , 75, 20);

                const date_1 = this.date1.split("-");
                const documentDate = date_1[2]+'-'+date_1[1]+'-'+date_1[0];
                doc.setFont("arial", "normal");
                doc.setFontSize(10);
                // doc.text('FOLIO: ' +  this.folio, 5, 35);
                doc.text('FECHA:', 5, 40);
                doc.text('RUT:', 5, 45);
                doc.text('RAZON SOCIAL:', 5, 50);

                doc.setFont("arial", "bold");
                doc.text(documentDate , 35, 40);
                doc.text(this.rut, 35, 45);
                doc.text(this.razon, 35, 50);

                doc.setFont("arial", "normal");
                doc.line(3, 54, 209, 54);
                doc.text('FECHA PAGO', 10, 58);
                doc.text('COMPROBANTE', 45, 58);
                doc.text('FORMA PAGO', 80, 58);
                doc.text('REFERENCIA', 120, 58);
                doc.text('MONTO', 185, 58);
                doc.line(3, 60, 209, 60);
                doc.setFontSize(9);
                var fila = 67;
                var pago_total = 0;
                this.proveedor_pagos.forEach(element => {
                    const date_doc = element.fecha.split("-");
                    const fecha_pago = date_doc[2]+'-'+date_doc[1]+'-'+date_doc[0];
                    const numero_pago = element.folio;
                    const forma_pago = element.formapago;
                    const ref_pago = element.referencia;
                    const monto_pago = element.monto;
                    doc.text(fecha_pago.toString(), 10, fila);
                    doc.text(numero_pago.toString(), 45, fila);
                    doc.text(forma_pago.toString(), 80, fila);
                    doc.text(ref_pago.toString(), 120, fila);
                    doc.text('$ ' + new Intl.NumberFormat("de-DE").format(parseFloat(monto_pago)), 185, fila);
                    pago_total = parseFloat(pago_total)+parseFloat(monto_pago);
                    fila = fila + 5;
                });
                fila = fila + 2;
                doc.line(3, fila, 209, fila);
                fila = fila + 5;
                doc.setFont("arial", "bold");
                doc.setFontSize(10);
                doc.text('CUENTA TOTAL: $ ' + new Intl.NumberFormat("de-DE").format(parseFloat(pago_total)), 155, fila);
                fila = fila + 3;
                doc.line(3, fila, 209, fila);

                //para visualizar
                doc.output('dataurlnewwindow');
                //para descargar
                //doc.save("a4.pdf");
            }
        },


    }
}
</script>