<template>
    <div style="margin-top:-15px">
        <h3>Ventas Pendientes</h3>
        <v-divider></v-divider>
        <v-row style="margin-top:-5px">
            <v-col cols="12" md="4">
                <v-card color="indigo" dark max-height="100">
                    <v-card-title>
                        <v-icon large>store</v-icon>
                        <v-spacer></v-spacer>
                        <h3>{{local}}</h3>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <strong style="font-family:calibri;font-size:9pt">LOCAL</strong>
                        <v-icon @click="TotalCotiza()" class="ml-2" color="white" small>refresh</v-icon>
                        <v-spacer></v-spacer>
                        <v-btn @click="verCotiza()" text x-small>detalle</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card color="red" dark max-height="100">
                    <v-card-title>
                        <v-icon large>shopping_cart</v-icon>
                        <v-spacer></v-spacer>
                        <h3>{{web}}</h3>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <strong style="font-family:calibri;font-size:9pt">WEB</strong>
                        <v-icon @click="TotalSitioWeb()" class="ml-2" color="white" small>refresh</v-icon>
                        <v-spacer></v-spacer>
                        <v-btn @click="verSitoWeb()" text x-small>detalle</v-btn>
                    </v-card-actions> 
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card color="orange" dark max-height="100">
                    <v-card-title>
                        <v-icon large>notifications</v-icon>
                        <v-spacer></v-spacer>
                        <h3>{{comercio}}</h3>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <strong style="font-family:calibri;font-size:9pt">COMERCIO</strong>
                        <v-icon @click="TotalComercio()" class="ml-2" color="white" small>refresh</v-icon>
                        <v-spacer></v-spacer>
                        <v-btn @click="verComercio()" text x-small>detalle</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" md="12">
                <v-card color="success" dark>
                    <v-card-title>
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-icon class="ml-2">monetization_on</v-icon>
                                <span class="ml-2 text-caption">Ventas</span>
                            </v-col>
                            <v-col cols="8" md="5">
                                <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">                                        
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip class="ml-2 text-caption" small @click="menu1 = true" v-bind="attrs" v-on="on" outlined color="white">
                                            <v-icon class="ml-2">events</v-icon>
                                            {{date1}}
                                        </v-chip>                                        
                                    </template>
                                <v-date-picker v-model="date1" @input="menu1 = false" no-title scrollable></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-divider inset vertical color="white"></v-divider>
                            <v-col cols="3" md="3" class="text-right">
                                <!-- <v-btn @click="verVentas(date1)" text x-small>detalle</v-btn> -->
                                <v-btn @click="resumenVentas(date1)" text x-small>resumen</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <!-- dialogo ventas -->
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="80%" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h6">
                    TOTAL: 
                    <strong class="success--text" >${{new Intl.NumberFormat("de-DE").format(parseFloat(total_ventas))}}</strong>
                </span>
                <v-spacer></v-spacer>
                <v-btn @click="dialog=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table dark :search="likesearch" :headers="headersResumen" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                    <template v-slot:item.accion="{ item }">
                        <a target="_blank" :href="item.ruta"><v-icon small>print</v-icon></a>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar" dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" class='mt-3'>
                        <a class="ml-3" v-if="descargaCSV===false" href="javascript:void(0)" @click="resumenCSV()"><img src="@/assets/csv.png" width="16px" height="20px"/></a>
                        <a class="ml-3" v-else :href="rutaCSV" style="text-decoration: none"><v-icon color='teal'>cloud_download</v-icon><span class="ml-2 text-caption">Descarga</span></a>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <!-- dialogo Cotiza -->
        <v-dialog v-model="dialogCotiza" transition="dialog-bottom-transition" max-width="80%" persistent>
        <v-card>
            <v-card-title>
                <span class="text-body-2 teal--text">Cotizaciones Pendientes</span>
                <v-spacer></v-spacer>
                <v-btn @click="dialogCotiza=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table dark :search="likesearch" :headers="headersCotiza" :items="itemsCotiza" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                    <template v-slot:item.accion="{ item }">
                        <v-icon small @click="deleteCotiza(item.numero)">delete</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                    </v-col>
                    <v-col cols="10" md="4">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Producto" dense required></v-text-field>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <!-- dialogo Ecommerce -->
        <v-dialog v-model="dialogEcommerce" transition="dialog-bottom-transition" max-width="80%" persistent>
        <v-card>
            <v-card-title>
                <span class="text-body-2 teal--text">Ventas x Facturar</span>
                <v-spacer></v-spacer>
                <v-btn @click="dialogEcommerce=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table dark :search="likesearch" :headers="headersEcommerce" :items="itemsEcommerce" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                    <template v-slot:item.accion>
                        <v-icon small class="text grey--text">block</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                    </v-col>
                    <v-col cols="10" md="4">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Dato" dense required></v-text-field>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        </v-dialog>


        <!-- dialogo detalle -->
        <v-dialog v-model="dialogo_detalle" transition="dialog-bottom-transition" max-width="80%" persistent>
        <v-card>
            <v-card-title>
                <span class="text-body-2 teal--text">Ventas x Facturar</span>
                <v-spacer></v-spacer>
                <v-btn @click="dialogo_detalle=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table dark :search="likesearch" :headers="titulo_detalle" :items="items_detalle" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                    <template v-slot:item.accion>
                        <v-icon small class="text grey--text">block</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                    </v-col>
                    <v-col cols="10" md="4">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Dato" dense required></v-text-field>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        </v-dialog>


        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 33px !important;
    /* background-color: lightyellow; */
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name: 'DashCompraVenta',
    components:{dialogo, snack},
    props: ['usu','contrato'],
    data(){
        return{
            descargaCSV:false,
            rutaCSV:'',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            portal:0,
            headers: [
                { text: 'Fecha', align: 'center', sortable: true, value: 'fecha' },
                { text: 'Documento', align: 'start', sortable: true, value: 'documento' },
                { text: 'Numero', align: 'start', sortable: true, value: 'numero' },
                { text: 'Cliente', align: 'start', sortable: true, value: 'cliente' },
                { text: 'Total', align: 'start', sortable: true, value: 'total' },
                { text: 'Pago', align: 'start', sortable: true, value: 'pago' },
                { text: 'Vendedor', align: 'start', sortable: true, value: 'usuario' },
                { text: 'Accion', align: 'center', sortable: false, value: 'accion' },
            ],
            headersCotiza: [
                { text: 'Fecha', align: 'center', sortable: true, value: 'fecha' },
                { text: 'Numero', align: 'start', sortable: true, value: 'numero' },
                { text: 'Cliente', align: 'start', sortable: true, value: 'cliente' },
                { text: 'Total', align: 'start', sortable: true, value: 'total' },
                { text: 'Vendedor', align: 'start', sortable: true, value: 'usuario' },
                { text: 'Accion', align: 'center', sortable: false, value: 'accion' },
            ],
            headersEcommerce: [
                { text: 'Fecha', align: 'center', sortable: true, value: 'fecha' },
                { text: 'Numero', align: 'start', sortable: true, value: 'numero' },
                { text: 'Cliente', align: 'start', sortable: true, value: 'cliente' },
                { text: 'Total', align: 'start', sortable: true, value: 'total' },
                { text: 'Vendedor', align: 'start', sortable: true, value: 'portal' },
                { text: 'Accion', align: 'center', sortable: false, value: 'accion' },
            ],
            items: [],
            itemsCotiza: [],
            itemsEcommerce: [],



            titulo_detalle: [
                { text: 'Fecha', align: 'center', sortable: true, value: 'fecha' },
                { text: 'Numero', align: 'start', sortable: true, value: 'numero' },
                { text: 'Cliente', align: 'start', sortable: true, value: 'cliente' },
                { text: 'Total', align: 'start', sortable: true, value: 'total' },
                { text: 'Vendedor', align: 'start', sortable: true, value: 'usuario' },
                { text: 'Accion', align: 'center', sortable: false, value: 'accion' },
            ],
            items_detalle: [],
            dialogo_detalle:false,
            dialog:false,
            dialogCotiza:false,
            dialogEcommerce:false,
            likesearch:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            local:0,
            web:0,
            comercio:0,
            menu1:false,
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            total_ventas:0,
            headersResumen:[
                { text: 'Fecha', align: 'start', sortable: false, value: 'fecha' },
                { text: 'Forma Pago', align: 'start', sortable: true, value: 'pago' },
                { text: 'Total', align: 'start', sortable: false, value: 'total' },
                { text: 'Vendedor', align: 'start', sortable: true, value: 'vendedor' },
                { text: 'Local', align: 'start', sortable: true, value: 'local' },
            ],
            ecommerces:[
                {id:90,name:'Mercado Libre'},
                {id:91,name:'Dafiti'},
                {id:92,name:'Falabella'},
                {id:93,name:'Ripley'},
                {id:94,name:'Cencosud'},
                {id:95,name:'JumpSeller'},
                {id:96,name:'Shopify'},
                {id:99,name:'Sito Web'},
            ],
            tk_fisico:'',
        }
    },
    mounted(){
        this.tk_fisico=localStorage.getItem('token');
        if (this.tk_fisico!=null){
            this.TotalCotiza();
            this.TotalEcommerce();        
        }else {
            this.$router.push({name:'Login'})
        }
    },
    methods:{
        //****************************** new resumen de ventas *************************** */
        async resumenVentas(fechaventa){
            this.loading=true;
            this.dialog=true;
            //console.log(fechaventa);
            try {

                let response;
                let sumaTotal=0;
                let resultado=[];
                let _totalVentas=0;

                //ventas del dia
                let arrayVentas=[];
                response = await axios.get(process.env.VUE_APP_URL + '/api/inv/DTE/hoy/'+this.contrato+'/'+fechaventa);
                arrayVentas=response.data;
                //console.log(arrayVentas);
                if (arrayVentas[0].existe=='si'){
                    //obtener todos los locales del array
                    let arrayLocal=[];
                    arrayVentas.forEach(element => {
                        if (!arrayLocal.includes(element.Local)){
                            arrayLocal.push(element.Local);
                        }
                    });
                    //console.log(arrayLocal);
                    //obtener todos los vendedores del array
                    let arrayVendedor=[];
                    arrayVentas.forEach(element => {
                        if (!arrayVendedor.includes(element.Usuario)){
                            arrayVendedor.push(element.Usuario);
                        }
                    });
                    //console.log(arrayVendedor);
                    //obtener todos las formas de pago del array
                    let arrayFormasPago=[];
                    arrayVentas.forEach(element => {
                        if (!arrayFormasPago.includes(element.Pago)){
                            arrayFormasPago.push(element.Pago);
                        }
                    });
                    //console.log(arrayFormasPago);
                    // recorrido 1 :: Locales
                    for (let index = 0; index < arrayLocal.length; index++) {
                        const recorrido_1 = arrayLocal[index];
                        //filtrar x local
                        const ventasLocal = arrayVentas.filter(res => res.Local==recorrido_1);
                        if (ventasLocal.length!=0){
                            //recorrido 2 :: Vendedores
                            for (let index = 0; index < arrayVendedor.length; index++) {
                                const recorrido_2 = arrayVendedor[index];
                                //filtrar x vendedor
                                const ventasVendedor = ventasLocal.filter(res => res.Usuario==recorrido_2);
                                if (ventasVendedor.length!=0){
                                    //recorrido 3 :: forma de pago
                                    for (let index = 0; index < arrayFormasPago.length; index++) {
                                        const recorrido_3 = arrayFormasPago[index];
                                        //filtrar x forma de pago
                                        const ventasPago = ventasVendedor.filter(res => res.Pago==recorrido_3);
                                        if (ventasPago.length!=0){
                                            //sumamos los totales
                                            sumaTotal=0;
                                            for (let index = 0; index < ventasPago.length; index++) {
                                                const element = ventasPago[index];
                                                sumaTotal=parseFloat(sumaTotal)+parseFloat(element.Total);
                                                _totalVentas=parseFloat(_totalVentas)+parseFloat(element.Total)                                                
                                            };
                                            // push de resultado
                                            resultado.push({ 
                                                'fecha':fechaventa,
                                                'pago':recorrido_3,
                                                'monto':sumaTotal,
                                                'total':new Intl.NumberFormat("de-DE").format(parseFloat(sumaTotal)),
                                                'vendedor':recorrido_2,
                                                'local':recorrido_1
                                            })
                                        } else { console.log('paso por recorrido 3') }
                                    }
                                } else { console.log('paso por recorrido 2') }
                            }
                        } else {
                            console.log('error: local sin venta')
                        }                        
                    };
                    //console.log(resultado);
                    this.items=resultado;
                    this.total_ventas=_totalVentas
                } else {
                    console.log('No existen ventas con fecha '+fechaventa)
                };
            } catch (error) {
                console.log(error)
            };
            this.loading=false;
        },
        async resumenCSV(){
            this.rutaCSV='javascript:void(0)';
            var date = new Date();
            const formatDate = (current_datetime)=>{
                let formatted_date = current_datetime.getFullYear() + "" + (current_datetime.getMonth() + 1) + "" + current_datetime.getDate() + "" + current_datetime.getHours() + "" + current_datetime.getMinutes() + "" + current_datetime.getSeconds();
                return formatted_date;
            };
            let csv;
            csv=[];
            //titulo
            csv.push({
                        'fecha':'Fecha',
                        'pago':'Forma Pago',
                        'total':'Total',
                        'vendedor':'Vendedor',
                        'local':'Local'
                    });
            this.items.forEach(element => {
                        csv.push({
                            'fecha':element.fecha,
                            'pago':element.pago,
                            'total':element.total,
                            'vendedor':element.vendedor,
                            'local':element.local
                        })
                    });
            const nameFile = 'resumen_ventas_'+formatDate(date);
            const params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
            const response = await axios.post(process.env.VUE_APP_URL + '/api/inv/csv/nuevo',params);
            if (response.data=='si'){
                this.rutaCSV=process.env.VUE_APP_URL + '/buzon/'+this.contrato+'/'+nameFile+'.csv';
                this.descargaCSV=true
            }
        },
        //******************************************************************************* */

        async fileCSV(){
            this.rutaCSV='javascript:void(0)';
            var date = new Date();
            const formatDate = (current_datetime)=>{
                let formatted_date = current_datetime.getFullYear() + "" + (current_datetime.getMonth() + 1) + "" + current_datetime.getDate() + "" + current_datetime.getHours() + "" + current_datetime.getMinutes() + "" + current_datetime.getSeconds();
                return formatted_date;
            };
            let csv;
            csv=[];
            //titulo
            csv.push({
                        'fecha':'Fecha',
                        'documento':'Documento',
                        'numero':'Numero',
                        'cliente':'Cliente',
                        'total':'Total',
                        'pago':'Pago',
                        'usuario':'Vendedor'
                    });
            this.items.forEach(element => {
                        csv.push({
                            'fecha':element.fecha,
                            'documento':element.documento,
                            'numero':element.numero,
                            'cliente':element.cliente,
                            'total':element.total,
                            'pago':element.pago,
                            'usuario':element.usuario
                        })
                    });
            const nameFile = 'ventas_'+formatDate(date);
            const params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
            const response = await axios.post(process.env.VUE_APP_URL + '/api/inv/csv/nuevo',params);
            if (response.data=='si'){
                this.rutaCSV=process.env.VUE_APP_URL + '/buzon/'+this.contrato+'/'+nameFile+'.csv';
                this.descargaCSV=true
            }
        },
        async verVentas(fechaventa){
            //console.log(fechaventa);
            this.descargaCSV=false;
            this.rutaCSV='';
            this.dialog=true
            this.loading=true;
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/DTE/'+this.contrato);
            const datos=response.data;
            this.items=[];
            let _totalVentas=0;
            this.total_ventas=0;
            //console.log(datos);
            if (datos[0].existe=='si'){ 
                for (let index = 0; index < datos.length; index++) {
                    const element = datos[index];
                    if (element.Fecha==fechaventa) {
                        this.items.push({
                            'fecha':element.Fecha,
                            'documento':element.Documento,
                            'numero':element.Numero,
                            'cliente':element.Cliente,
                            'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                            'pago':element.Pago,
                            'usuario':element.Usuario,
                            'ruta':element.RutaPdf
                        });
                        _totalVentas=parseFloat(_totalVentas)+parseFloat(element.Total);
                    };
                };
                this.total_ventas=_totalVentas;
                // datos.forEach(element => {
                //     this.items.push({
                //         'fecha':element.Fecha,
                //         'documento':element.Documento,
                //         'numero':element.Numero,
                //         'cliente':element.Cliente,
                //         'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                //         'pago':element.Pago,
                //         'usuario':element.Usuario,
                //         'comision':new Intl.NumberFormat("de-DE").format(parseFloat(element.Comision)),
                //         'ruta':element.RutaPdf
                //     })
                // });
            };
            this.loading=false;
        },
        async deleteCotiza(n){
            this.loading=true;
            const response = await axios.delete(process.env.VUE_APP_URL + '/api/inv/orden/delete/'+this.contrato+'/'+n);
            const datos=response.data;
            if (datos=='ok'){
                this.itemsCotiza = this.itemsCotiza.filter(function(car) {
                    return car.numero !== n; 
                });
                //actualiza ecomerce si existe
                await axios.put(process.env.VUE_APP_URL1 + '/api/ML/products/orders/status/V/'+n+'/'+this.contrato);
            };
            this.loading=false;
        },


//**********************************************************************************************************
        async TotalEcommerce(){
            this.loading=true;
            try {
                const params = {
                    "contrato":this.contrato,
	                "user":this.usu
                };
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.tk_fisico
                };
                const response = await axios.post(process.env.VUE_APP_URL1 + '/api/v1/total/orders/',params,{headers});
                const datos=response.data;
                if (datos[0].existe=='si'){
                    let webTotal=0;
                    let ecoTotal=0;
                    datos.forEach(element => {
                        if(parseInt(element.Portal)!=99){                            
                            ecoTotal++;                            
                        } else {
                            webTotal++;
                        }
                    });
                    this.web=webTotal;
                    this.comercio=ecoTotal;
                }
            } catch (error) {
                console.log(error)
            }
            this.loading=false;
        },
        async TotalCotiza(){
            this.loading=true;
            try {
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/ordenes/n/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){ 
                    this.local=parseFloat(datos[0].Total)
                }                
            } catch (error) {
                console.log(error)
            }
            this.loading=false;
        },
        async verCotiza(){
            this.loading=true;
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/ordenes/'+this.contrato);
            const datos=response.data;
            this.items_detalle=[];
            if (datos[0].existe=='si'){   
                for (let index = 0; index < datos.length; index++) {
                    const element = datos[index];
                    this.items_detalle.push({
                        'fecha':element.Fecha,
                        'numero':element.Orden,
                        'cliente':element.Nombre,
                        'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                        'usuario':element.Usuario,
                    });
                }              
            };
            this.dialogo_detalle=true
            this.loading=false;
        },
        async verSitoWeb(){
            this.loading=true;
            try {
                const params = {
                    "contrato":this.contrato,
	                "user":this.usu
                };
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.tk_fisico
                };
                const response = await axios.post(process.env.VUE_APP_URL1 + '/api/v1/total/orders/',params,{headers});
                const datos=response.data;
                if (datos[0].existe=='si'){
                    let webTotal=0;
                    this.items_detalle=[];
                    datos.forEach(element => {
                        const ecoData=this.ecommerces.filter(res => res.id==parseInt(element.Portal));
                        if(parseInt(element.Portal)==99){
                            this.items_detalle.push({
                                'fecha':element.Fecha,
                                'numero':element.Orden,
                                'cliente':'Ecommerce',
                                'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                'usuario':ecoData[0].name,
                            });
                            webTotal++;   
                        }                        
                    });
                    this.web=webTotal;
                }
            } catch (error) {
                console.log(error)
            }
            this.dialogo_detalle=true
            this.loading=false;
        },
        async verComercio(){
            this.loading=true;
            try {
                const params = {
                    "contrato":this.contrato,
	                "user":this.usu
                };
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.tk_fisico
                };
                const response = await axios.post(process.env.VUE_APP_URL1 + '/api/v1/total/orders/',params,{headers});
                const datos=response.data;
                if (datos[0].existe=='si'){
                    let ecoTotal=0;
                    this.items_detalle=[];
                    datos.forEach(element => {
                        const ecoData=this.ecommerces.filter(res => res.id==parseInt(element.Portal));
                        if(parseInt(element.Portal)!=99){
                            this.items_detalle.push({
                                'fecha':element.Fecha,
                                'numero':element.Orden,
                                'cliente':'Ecommerce',
                                'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                                'usuario':ecoData[0].name,
                            });
                            ecoTotal++; 
                        }                        
                    });
                    this.comercio=ecoTotal;
                }
            } catch (error) {
                console.log(error)
            }
            this.dialogo_detalle=true
            this.loading=false;
        }
        // async verSitoWeb(){
        //     this.loading=true;
        //     const response = await axios.get('http://www.app.bodeganet.cl/ApiEcommerce/public/api/ecommerce/details/orders/'+this.contrato);
        //     const datos=response.data;
        //     this.items_detalle=[];
        //     if (datos[0].existe=='si'){   
        //         for (let index = 0; index < datos.length; index++) {
        //             const element = datos[index];
        //             if(parseInt(element.Portal)==0){
        //                 this.items_detalle.push({
        //                     'fecha':element.Fecha,
        //                     'numero':element.Orden,
        //                     'cliente':element.Nombre,
        //                     'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
        //                     'usuario':element.PortalName,
        //                 });                    
        //             }
        //         }              
        //     };
        //     this.dialogo_detalle=true
        //     this.loading=false;
        // },
        // async verComercio(){
        //     this.loading=true;
        //     const response = await axios.get('http://www.app.bodeganet.cl/ApiEcommerce/public/api/ecommerce/details/orders/'+this.contrato);
        //     const datos=response.data;
        //     this.items_detalle=[];
        //     if (datos[0].existe=='si'){   
        //         for (let index = 0; index < datos.length; index++) {
        //             const element = datos[index];
        //             if(parseInt(element.Portal)!=0){
        //                 this.items_detalle.push({
        //                     'fecha':element.Fecha,
        //                     'numero':element.Orden,
        //                     'cliente':element.Nombre,
        //                     'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
        //                     'usuario':element.PortalName,
        //                 });                    
        //             }
        //         }              
        //     };
        //     this.dialogo_detalle=true
        //     this.loading=false;
        // },

//**********************************************************************************************************        
    }
}
</script>