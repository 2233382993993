<template>
<div>
    <v-snackbar v-model="msgbox" timeout="3000" :color="color">
        {{Msg}}
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs">Close</v-btn>
        </template>
    </v-snackbar>
</div>
</template>
<script>
export default {
    name:'snack',
    props:['Msg','color','msgbox'],
    // data(){
    //     return { msgbox:false }
    // }
}
</script>