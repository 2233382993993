<template>
    <div class="mt-3">
        <h3>Crecimiento ({{anioAnt}}-{{anioAct}})</h3>
        <v-divider></v-divider>
        <div>
            <v-row class="text-center">
                <v-col cols="12" md="4">
                    <h6>{{anioAnt}}</h6>
                    <v-progress-circular :rotate="360" :size="80" :width="15" :value="porAnt" :color="colorAnt">{{porAnt}}%</v-progress-circular>
                    <h6>${{new Intl.NumberFormat("de-DE").format(montoAnt)}}</h6>
                </v-col>
                <v-col cols="12" md="4">
                    <h6>{{anioAct}}</h6>
                    <v-progress-circular :rotate="360" :size="80" :width="15" :value="porAct" :color="colorAct">{{porAct}}%</v-progress-circular>
                    <h6>${{new Intl.NumberFormat("de-DE").format(montoAct)}}</h6>
                </v-col>
                <v-col cols="12" md="4">
                    <h6>Crecimiento {{crece}}</h6>
                    <v-progress-circular :rotate="360" :size="80" :width="15" :value="porCrece" :color="colorcrece">{{porCrece}}%</v-progress-circular>
                    <h6>${{new Intl.NumberFormat("de-DE").format(montoCrece)}}</h6>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'ChartCrece',
  props:['usu','contrato'],
  data(){
    return {
        //contrato:'123',
        anioAnt:'',
        montoAnt:0,
        porAnt:0,
        colorAnt:'amber',
        anioAct:'',
        montoAct:0,
        porAct:0,
        colorAct:'amber',
        crece:'',
        montoCrece:0,
        porCrece:0,
        colorcrece:'amber',
    }
  },
  async mounted(){
    const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/cartera/crecimiento/anual/'+this.contrato);
    const datos=response.data;
    //console.log(datos);
    if (datos[0].existe=='si'){
        this.anioAnt=datos[0].anioAnterior;
        this.montoAnt=parseFloat(datos[0].montoAnterior);
        this.anioAct=datos[0].anioActual;
        this.montoAct=parseFloat(datos[0].montoActual);
        // calculamos estadisticas
        const porTotal=this.montoAct+this.montoAnt;
        if (porTotal>0){
            this.porAnt= Math.round((this.montoAnt*100)/porTotal);
            this.porAct=Math.round((this.montoAct*100)/porTotal);
            this.porCrece=this.porAct-this.porAnt;
            if (this.montoAct>this.montoAnt){
                this.colorAnt='red';
                this.colorAct='indigo';
            } else {
                 if (this.montoAct<this.montoAnt){
                    this.colorAnt='indigo';
                    this.colorAct='red';
                 } else { // =0
                    this.colorAnt='amber';
                    this.colorAct='amber';
                 }
            }
            this.montoCrece=this.montoAct-this.montoAnt
            if (this.montoCrece>0){
                this.crece='Alto';
                this.colorcrece='success';
            } else {
                if (this.montoCrece<0){
                    this.crece='bajo';
                    this.colorcrece='red';
                } else { // =0
                    this.crece='Neutro';
                    this.colorcrece='amber';
                }
            }        
        }
    }     
  }
}
</script>