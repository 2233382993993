<template>
    <div>
        <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">Metrica</h2> <!-- Full Screen -->
        <h3 class="mt-1 hidden-sm-and-up grey--text">Metrica</h3> <!-- Mobile Screen -->
        <div v-if="vista===true" class="mt-5">
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-row>
                                <v-col cols="12" md="4">
                                    <h6 class="ml-3">Periodo</h6>
                                    <v-select class='text-caption' v-model="periodo" :items="periodos" outlined dense rounded></v-select>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-btn @click="searchPeriodo()" class="mt-5" color="indigo" fab small dark><v-icon small>search</v-icon></v-btn> 
                                </v-col>
                            </v-row>
                            <h3>Canales de Venta ({{ periodo }})</h3>
                            <v-divider></v-divider>
                            <v-row class="text-center">
                                <v-col cols="12" md="2">
                                    <h6>Local ( {{ new Intl.NumberFormat("de-DE").format(parseInt(totalCantidadLocal_anio)) }} )</h6>
                                    <v-progress-circular :rotate="360" :size="80" :width="15" :value="porcientoLocal_anio" color="indigo">{{porcientoLocal_anio}}%</v-progress-circular>
                                    <h6>$ {{ new Intl.NumberFormat("de-DE").format(parseInt(totalVentasLocal_anio)) }}</h6>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <h6>Web ( {{ new Intl.NumberFormat("de-DE").format(parseInt(totalCantidadWeb_anio)) }} )</h6>
                                    <v-progress-circular :rotate="360" :size="80" :width="15" :value="porcientoWeb_anio" color="red">{{porcientoWeb_anio}}%</v-progress-circular>
                                    <h6>$ {{ new Intl.NumberFormat("de-DE").format(parseInt(totalVentasWeb_anio)) }}</h6>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <h6>Comercio ( {{ new Intl.NumberFormat("de-DE").format(parseInt(totalCantidadEcommerce_anio)) }} )</h6>
                                    <v-progress-circular :rotate="360" :size="80" :width="15" :value="porcientoEcommerce_anio" color="warning">{{porcientoEcommerce_anio}}%</v-progress-circular>
                                    <h6>$ {{ new Intl.NumberFormat("de-DE").format(parseInt(totalVentasEcommerce_anio)) }}</h6>
                                </v-col>
                                <v-col cols="12" md="6" class="text-start">
                                    <h6>Producto mas Vendido</h6>
                                    <v-divider class="mx-4"></v-divider>
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <h5>Sku: <span class="teal--text">{{ anio_sku }}</span></h5>
                                            <h5>Producto: <span class="teal--text">{{ anio_producto }}</span></h5>
                                            <h5>Ventas: <span class="teal--text">$ {{ new Intl.NumberFormat("de-DE").format(parseInt(anio_ventas)) }}</span></h5>
                                            <h5>Cantidad: <span class="teal--text">{{ new Intl.NumberFormat("de-DE").format(parseInt(anio_cantidad)) }}</span></h5>
                                            <h5>Canal: <span class="teal--text">{{ anio_portal }}</span></h5>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <h3>Metrica de Negocio ({{ periodo }})</h3>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-data-table fixed-header :headers="headerAnio" :items="itemAnio" :page.sync="page_anio" :items-per-page="itemsPerPage_anio" hide-default-footer class="elevation-1 text-size" @page-count="pageCount_anio = $event" dark height="250px">
                                        <template v-slot:item.tVenta="{ item }">
                                            {{ new Intl.NumberFormat("de-DE").format(parseInt(item.tVenta)) }}
                                        </template>
                                    </v-data-table>
                                    <!-- <v-pagination v-model="page_anio" :length="pageCount_anio" total-visible="10"></v-pagination> -->
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-row>
                                <v-col cols="12" md="4">
                                    <h6 class="ml-3">Trimestre</h6>
                                    <v-select class='text-caption' v-model="trimestre" :items="trimestres" outlined dense rounded></v-select>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-btn @click="searchTrimestre()" class="mt-5" color="indigo" fab small dark><v-icon small>search</v-icon></v-btn> 
                                </v-col>
                            </v-row>
                            <h3>Canales de Venta <strong class="text-caption">({{ trimestre }})</strong></h3>
                            <v-divider></v-divider>
                            <v-row class="text-center">
                                <v-col cols="12" md="2">
                                    <h6>Local ( {{ new Intl.NumberFormat("de-DE").format(parseInt(totalCantidadLocal_tri)) }} )</h6>
                                    <v-progress-circular :rotate="360" :size="80" :width="15" :value="porcientoLocal_tri" color="indigo">{{porcientoLocal_tri}}%</v-progress-circular>
                                    <h6>$ {{ new Intl.NumberFormat("de-DE").format(parseInt(totalVentasLocal_tri)) }}</h6>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <h6>Web ( {{ new Intl.NumberFormat("de-DE").format(parseInt(totalCantidadWeb_tri)) }} )</h6>
                                    <v-progress-circular :rotate="360" :size="80" :width="15" :value="porcientoWeb_tri" color="red">{{porcientoWeb_tri}}%</v-progress-circular>
                                    <h6>$ {{ new Intl.NumberFormat("de-DE").format(parseInt(totalVentasWeb_tri)) }}</h6>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <h6>Comercio ( {{ new Intl.NumberFormat("de-DE").format(parseInt(totalCantidadEcommerce_tri)) }} )</h6>
                                    <v-progress-circular :rotate="360" :size="80" :width="15" :value="porcientoEcommerce_tri" color="warning">{{porcientoEcommerce_tri}}%</v-progress-circular>
                                    <h6>$ {{ new Intl.NumberFormat("de-DE").format(parseInt(totalVentasEcommerce_tri)) }}</h6>
                                </v-col>
                                <v-col cols="12" md="6" class="text-start">
                                    <h6>Producto mas Vendido</h6>
                                    <v-divider class="mx-4"></v-divider>
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <h5>Sku: <span class="teal--text">{{ tri_sku }}</span></h5>
                                            <h5>Producto: <span class="teal--text">{{ tri_producto }}</span></h5>
                                            <h5>Ventas: <span class="teal--text">$ {{ new Intl.NumberFormat("de-DE").format(parseInt(tri_ventas)) }}</span></h5>
                                            <h5>Cantidad: <span class="teal--text">{{ new Intl.NumberFormat("de-DE").format(parseInt(tri_cantidad)) }}</span></h5>
                                            <h5>Canal: <span class="teal--text">{{ tri_portal }}</span></h5>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <h3>Metrica de Negocio <strong class="text-caption">({{ trimestre }})</strong></h3>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-data-table @click:row="handleDetalle" fixed-header :headers="headerTrimestre" :items="itemTrimestre" :page.sync="page_tri" :items-per-page="itemsPerPage_tri" hide-default-footer class="elevation-1 text-size" @page-count="pageCount_tri = $event" dark height="250px">
                                        <template v-slot:item.tVenta="{ item }">
                                            {{ new Intl.NumberFormat("de-DE").format(parseInt(item.tVenta)) }}
                                        </template>
                                    </v-data-table>
                                    <!-- <v-pagination v-model="page_tri" :length="pageCount_tri" total-visible="10"></v-pagination> -->
                                </v-col>
                            </v-row>
                        </v-col>                        
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
        <div v-else-if="vista===false" class="mt-5">
            <v-card>
                <v-card-subtitle><v-icon class="red--text">cancel</v-icon><span class="ml-2">Solicitud Denegada</span></v-card-subtitle>
            </v-card>
        </div>
        <v-dialog v-model="modal" persistent max-width="75%">
            <v-card>
                <v-card-title>
                    Metrica del Producto
                    <v-spacer></v-spacer>
                    <v-icon @click="modal=false" color="red">cancel</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row v-for="item in detalle" :key="item.id">
                            <v-col cols="12" md="3">
                                <v-img :src="item.imagenes[0]" width="200" height="200"></v-img>
                            </v-col>
                            <v-col cols="12" md="3">
                                <h6 class="grey--text">SKU {{item.sku}}</h6>
                                <h5 class="grey--text">{{item.nombre}}</h5>
                                <h4 class="grey--text">{{item.marca}}</h4>
                                <h6 class="grey--text">{{item.caracteristica}}</h6>
                                <h6 class="grey--text">Stock {{item.stock}}</h6>
                                <h3>${{new Intl.NumberFormat("de-DE").format(parseFloat(item.precioLocal))}}</h3>
                            </v-col>
                            <v-col cols="12" md="6" class="text-center">
                                <h1>{{ periodo }}</h1>
                                <v-sparkline :value="chart" :labels="labels" color="success" height="100" padding="24" stroke-linecap="round" smooth
                                :fill="fill" auto-draw></v-sparkline>
                            </v-col>
                            </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <dialogo :loading="loading"  />
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 8pt !important;
    height: 25px !important;
    /* background-color: lightyellow; */
}
</style>
<script>
import axios from 'axios';
import dialogo from '@/components/dialogo.vue';
export default {
  name: 'Metrica',
  components: { dialogo },
  props: ['usu','contrato'],
  data(){
    return{
      loading:false,
      vista:null,
      periodo:0,
      periodos:[2022,2023,2024],
      trimestre:'',
      trimestres:['ENE-MAR','ABR-JUN','JUL-SEP','OCT-DIC'],
      headerAnio:[
        {text: 'Sku',align: 'start',sortable: true, value: 'tSku'},
        {text: 'Producto',align: 'start',sortable: false, value: 'tProducto'},
        {text: 'Venta ($)',align: 'center',sortable: true, value: 'tVenta'},
        {text: 'Local',align: 'center',sortable: true, value: 'tLocal'},
        {text: 'Web',align: 'center',sortable: true, value: 'tWeb'},
        {text: 'Ecommrce',align: 'center',sortable: true, value: 'tEcommerce'},
      ],
      itemAnio: [],
      page_anio: 1,
      pageCount_anio: 0,
      itemsPerPage_anio: 1000000,
      headerTrimestre:[
        {text: 'Sku',align: 'start',sortable: true, value: 'tSku'},
        {text: 'Producto',align: 'start',sortable: false, value: 'tProducto'},
        {text: 'Venta ($)',align: 'center',sortable: true, value: 'tVenta'},
        {text: 'Local',align: 'center',sortable: true, value: 'tLocal'},
        {text: 'Web',align: 'center',sortable: true, value: 'tWeb'},
        {text: 'Ecommrce',align: 'center',sortable: true, value: 'tEcommerce'},
      ],
      itemTrimestre: [],
      page_tri: 1,
      pageCount_tri: 0,
      itemsPerPage_tri: 1000000,
      desde:'',
      hasta:'',
      metrica_anio:[],
      metrica_trimestre:[],

      totalVentasLocal_anio:0,
      totalVentasWeb_anio:0,
      totalVentasEcommerce_anio:0,
      totalCantidadLocal_anio:0,
      totalCantidadWeb_anio:0,
      totalCantidadEcommerce_anio:0,

      totalVentasLocal_tri:0,
      totalVentasWeb_tri:0,
      totalVentasEcommerce_tri:0,
      totalCantidadLocal_tri:0,
      totalCantidadWeb_tri:0,
      totalCantidadEcommerce_tri:0,

      porcientoLocal_anio:0,
      porcientoWeb_anio:0,
      porcientoEcommerce_anio:0,
      porcientoLocal_tri:0,
      porcientoWeb_tri:0,
      porcientoEcommerce_tri:0,

      anio_sku:'',
      anio_producto:'',
      anio_ventas:0,
      anio_cantidad:0,
      anio_portal:'',

      tri_sku:'',
      tri_producto:'',
      tri_ventas:0,
      tri_cantidad:0,
      tri_portal:'',

      modal:false,
      detalle:[],
      chart: [],
      labels: ['ene','feb','mar','abr','may','jun','jul','ago','sep','oct','nov','dic'],
      fill: false,
      type: 'trend',
      autoLineWidth: false,
    }
  },
  async mounted(){
    await this.entrada();
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    this.periodo = year;
    //trimestre
    if(parseInt(month)>=1 && parseInt(month)>=3){ this.trimestre='ENE-MAR' };
    if(parseInt(month)>=4 && parseInt(month)>=6){ this.trimestre='ABR-JUN' };
    if(parseInt(month)>=7 && parseInt(month)>=9){ this.trimestre='JUL-SEP' };
    if(parseInt(month)>=10 && parseInt(month)>=12){ this.trimestre='OCT-DIC' };
    await this.MetricaAnio(this.periodo);
    await this.MetricaTrimestre(this.periodo,this.trimestre);
  },
  methods:{
    async entrada(){
      this.loading=true;
      try {
        const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
        const datos=response.data;
        if (datos[0].existe=='si'){
            if(datos[0].Permiso_1_1!=0) {
            this.vista=true;
            } else { this.vista=false; }
        } else { this.vista=false; }        
      } catch (error) {
        console.log(error)
      }
      this.loading=false;
    },
    async clsInput_Anio(){
        this.itemAnio=[];
        this.metrica_anio=[];

        this.totalVentasLocal_anio=0;
        this.totalVentasWeb_anio=0;
        this.totalVentasEcommerce_anio=0;
        this.totalCantidadLocal_anio=0;
        this.totalCantidadWeb_anio=0;
        this.totalCantidadEcommerce_anio=0;

        this.porcientoLocal_anio=0;
        this.porcientoWeb_anio=0;
        this.porcientoEcommerce_anio=0;

        this.anio_sku='';
        this.anio_producto='';
        this.anio_ventas=0;
        this.anio_cantidad=0;
        this.anio_portal='';

    },
    async clsInput_Trimestre(){
        this.itemTrimestre=[];
        this.metrica_trimestre=[];

        this.totalVentasLocal_tri=0;
        this.totalVentasWeb_tri=0;
        this.totalVentasEcommerce_tri=0;
        this.totalCantidadLocal_tri=0;
        this.totalCantidadWeb_tri=0;
        this.totalCantidadEcommerce_tri=0;

        this.porcientoLocal_tri=0;
        this.porcientoWeb_tri=0;
        this.porcientoEcommerce_tri=0;

        this.tri_sku='';
        this.tri_producto='';
        this.tri_ventas=0;
        this.tri_cantidad=0;
        this.tri_portal='';
    },
    async MetricaAnio(myPeriodo){
        this.loading=true;
        try {
            let res;
            //periodo
            this.desde = myPeriodo + '-01-01';
            this.hasta = myPeriodo + '-12-30';
            res = await axios.get(process.env.VUE_APP_URL + '/api/inv/v1/metrica/'+this.contrato+'/'+this.desde + '/' + this.hasta + '/');
            const datos_anio = res.data;
            if (datos_anio[0].existe=='si'){ 
                this.metrica_anio = datos_anio
            };
            //procesar data metrica anio
            let mysku_anio = '';
            let myproducto_anio = '';
            let myventas_anio = 0;
            let mycantidad_anio = 0;
            let myportal_anio='';
            let sumaVentas_anio = 0;
            let sumaCantidad_anio = 0;
            let sumaVentasLocal_anio = 0;
            let sumaVentasWeb_anio = 0;
            let sumaVentasEcommerce_anio = 0;
            let sumaCantidadLocal_anio = 0;
            let sumaCantidadWeb_anio = 0;
            let sumaCantidadEcommerce_anio = 0;
            if(this.metrica_anio.length!=0){
                this.metrica_anio.forEach(element => {                    
                    sumaVentas_anio = parseFloat(element.VentasLocal) + parseFloat(element.VentasWeb) + parseFloat(element.VentasEcommerce);
                    sumaVentasLocal_anio = parseFloat(sumaVentasLocal_anio) + parseFloat(element.VentasLocal);
                    sumaVentasWeb_anio = parseFloat(sumaVentasWeb_anio) + parseFloat(element.VentasWeb);
                    sumaVentasEcommerce_anio = parseFloat(sumaVentasEcommerce_anio) + parseFloat(element.VentasEcommerce);
                    sumaCantidad_anio = parseFloat(element.CantidadLocal) + parseFloat(element.CantidadWeb) + parseFloat(element.CantidadEcommerce);
                    sumaCantidadLocal_anio = parseFloat(sumaCantidadLocal_anio) + parseFloat(element.CantidadLocal);
                    sumaCantidadWeb_anio = parseFloat(sumaCantidadWeb_anio) + parseFloat(element.CantidadWeb);
                    sumaCantidadEcommerce_anio = parseFloat(sumaCantidadEcommerce_anio) + parseFloat(element.CantidadEcommerce);
                    this.itemAnio.push(
                        {
                            'tSku': element.Sku,
                            'tProducto': element.Producto,
                            'tVenta': sumaVentas_anio,
                            'tLocal': element.CantidadLocal,
                            'tWeb': element.CantidadWeb,
                            'tEcommerce': element.CantidadEcommerce
                        }
                    );
                    //mayor venta
                    if(parseFloat(sumaVentas_anio) > parseFloat(myventas_anio)){
                        mysku_anio = element.Sku;
                        myproducto_anio = element.Producto;
                        myventas_anio = parseFloat(sumaVentas_anio);
                        mycantidad_anio = parseFloat(sumaCantidad_anio);
                        if(parseFloat(element.VentasLocal) > parseFloat(element.VentasWeb) && 
                            parseFloat(element.VentasLocal) > parseFloat(element.VentasEcommerce)){
                                myportal_anio = 'Local'
                        }
                        if(parseFloat(element.VentasWeb) > parseFloat(element.VentasLocal) && 
                            parseFloat(element.VentasWeb) > parseFloat(element.VentasEcommerce)){
                                myportal_anio = 'Web'
                        }
                        if(parseFloat(element.VentasEcommerce) > parseFloat(element.VentasLocal) && 
                            parseFloat(element.VentasEcommerce) > parseFloat(element.VentasWeb)){
                                myportal_anio = 'Ecommerce'
                        }
                    }
                });
                //asignamos la mayor venta
                this.anio_sku = mysku_anio;
                this.anio_producto = myproducto_anio;
                this.anio_ventas = myventas_anio;
                this.anio_cantidad = mycantidad_anio;
                this.anio_portal = myportal_anio;
                //asignamos totales
                this.totalVentasLocal_anio = sumaVentasLocal_anio;
                this.totalVentasWeb_anio = sumaVentasWeb_anio;
                this.totalVentasEcommerce_anio = sumaVentasEcommerce_anio;
                this.totalCantidadLocal_anio = sumaCantidadLocal_anio;
                this.totalCantidadWeb_anio = sumaCantidadWeb_anio;
                this.totalCantidadEcommerce_anio = sumaCantidadEcommerce_anio
                //obtener porcentajes
                const total_anio = parseFloat(this.totalCantidadLocal_anio) + parseFloat(this.totalCantidadWeb_anio) + parseFloat(this.totalCantidadEcommerce_anio);
                if(parseFloat(total_anio)!=0){
                    const local_anio = parseFloat((parseFloat(this.totalCantidadLocal_anio) * 100) / parseFloat(total_anio)).toFixed(2);
                    const ecommerce_anio = parseFloat((parseFloat(this.totalCantidadEcommerce_anio) * 100) / parseFloat(total_anio)).toFixed(2);
                    const web_anio = parseFloat(100 - (parseFloat(local_anio) + parseFloat(ecommerce_anio))).toFixed(2);
                    this.porcientoLocal_anio = local_anio;
                    this.porcientoWeb_anio = web_anio;
                    this.porcientoEcommerce_anio = ecommerce_anio;
                }
            }
        } catch (error) {
            console.log(error)
        }
        this.loading=false;
    },
    async MetricaTrimestre(myPeriodo, myTrimestre){
        this.loading=true;
        try {
            let res;
            //trimestres
            switch (myTrimestre) {
                case 'ENE-MAR':
                    this.desde = myPeriodo + '-01-01';
                    this.hasta = myPeriodo + '-01-30';
                break;
                case 'ABR-JUN':
                    this.desde = myPeriodo + '-04-01';
                    this.hasta = myPeriodo + '-06-30';
                break;
                case 'JUL-SEP':
                    this.desde = myPeriodo + '-07-01';
                    this.hasta = myPeriodo + '-09-30';
                break;
                case 'OCT-DIC':
                    this.desde = myPeriodo + '-10-01';
                    this.hasta = myPeriodo + '-12-30';
                break;
            };
            res = await axios.get(process.env.VUE_APP_URL + '/api/inv/v1/metrica/'+this.contrato+'/'+this.desde + '/' + this.hasta + '/');
            const datos_tri = res.data;
            if (datos_tri[0].existe=='si'){ 
                this.metrica_trimestre = datos_tri
            };
            //procesar data metrica trimestre
            let mysku_tri = '';
            let myproducto_tri = '';
            let myventas_tri = 0;
            let mycantidad_tri = 0;
            let myportal_tri='';
            let sumaVentas_tri = 0;
            let sumaCantidad_tri = 0;
            let sumaVentasLocal_tri = 0;
            let sumaVentasWeb_tri = 0;
            let sumaVentasEcommerce_tri = 0;
            let sumaCantidadLocal_tri = 0;
            let sumaCantidadWeb_tri = 0;
            let sumaCantidadEcommerce_tri = 0;
            if(this.metrica_anio.length!=0){
                this.metrica_trimestre.forEach(element => {
                    sumaVentas_tri = parseFloat(element.VentasLocal) + parseFloat(element.VentasWeb) + parseFloat(element.VentasEcommerce);
                    sumaVentasLocal_tri = parseFloat(sumaVentasLocal_tri) + parseFloat(element.VentasLocal);
                    sumaVentasWeb_tri = parseFloat(sumaVentasWeb_tri) + parseFloat(element.VentasWeb);
                    sumaVentasEcommerce_tri = parseFloat(sumaVentasEcommerce_tri) + parseFloat(element.VentasEcommerce);
                    sumaCantidad_tri = parseFloat(element.CantidadLocal) + parseFloat(element.CantidadWeb) + parseFloat(element.CantidadEcommerce);
                    sumaCantidadLocal_tri = parseFloat(sumaCantidadLocal_tri) + parseFloat(element.CantidadLocal);
                    sumaCantidadWeb_tri = parseFloat(sumaCantidadWeb_tri) + parseFloat(element.CantidadWeb);
                    sumaCantidadEcommerce_tri = parseFloat(sumaCantidadEcommerce_tri) + parseFloat(element.CantidadEcommerce);
                    this.itemTrimestre.push(
                        {
                            'tSku': element.Sku,
                            'tProducto': element.Producto,
                            'tVenta': sumaVentas_tri,
                            'tLocal': element.CantidadLocal,
                            'tWeb': element.CantidadWeb,
                            'tEcommerce': element.CantidadEcommerce
                        }
                    )
                    //mayor venta
                    if(parseFloat(sumaVentas_tri) > parseFloat(myventas_tri)){
                        mysku_tri = element.Sku;
                        myproducto_tri = element.Producto;
                        myventas_tri = parseFloat(sumaVentas_tri);
                        mycantidad_tri = parseFloat(sumaCantidad_tri);
                        if(parseFloat(element.VentasLocal) > parseFloat(element.VentasWeb) && 
                            parseFloat(element.VentasLocal) > parseFloat(element.VentasEcommerce)){
                                myportal_tri = 'Local'
                        }
                        if(parseFloat(element.VentasWeb) > parseFloat(element.VentasLocal) && 
                            parseFloat(element.VentasWeb) > parseFloat(element.VentasEcommerce)){
                                myportal_tri = 'Web'
                        }
                        if(parseFloat(element.VentasEcommerce) > parseFloat(element.VentasLocal) && 
                            parseFloat(element.VentasEcommerce) > parseFloat(element.VentasWeb)){
                                myportal_tri = 'Ecommerce'
                        }
                    }
                });
                //asignamos la mayor venta
                this.tri_sku = mysku_tri;
                this.tri_producto = myproducto_tri;
                this.tri_ventas = myventas_tri;
                this.tri_cantidad = mycantidad_tri;
                this.tri_portal = myportal_tri;
                //asignamos totales
                this.totalVentasLocal_tri = sumaVentasLocal_tri;
                this.totalVentasWeb_tri = sumaVentasWeb_tri;
                this.totalVentasEcommerce_tri = sumaVentasEcommerce_tri;
                this.totalCantidadLocal_tri = sumaCantidadLocal_tri;
                this.totalCantidadWeb_tri = sumaCantidadWeb_tri;
                this.totalCantidadEcommerce_tri = sumaCantidadEcommerce_tri
                //obtener porcentajes
                const total_tri = parseFloat(this.totalCantidadLocal_tri) + parseFloat(this.totalCantidadWeb_tri) + parseFloat(this.totalCantidadEcommerce_tri);
                if(parseFloat(total_tri)!=0){
                    const local_tri = parseFloat((parseFloat(this.totalCantidadLocal_tri) * 100) / parseFloat(total_tri)).toFixed(2);
                    const ecommerce_tri = parseFloat((parseFloat(this.totalCantidadEcommerce_tri) * 100) / parseFloat(total_tri)).toFixed(2);
                    const web_tri = parseFloat(100 - (parseFloat(local_tri) + parseFloat(ecommerce_tri))).toFixed(2);
                    this.porcientoLocal_tri = local_tri;
                    this.porcientoWeb_tri = web_tri;
                    this.porcientoEcommerce_tri = ecommerce_tri;
                }
            }
        } catch (error) {
            console.log(error)
        }
        this.loading=false;
    },
    async searchPeriodo(){
        this.clsInput_Anio();
        this.clsInput_Trimestre();
        await this.MetricaAnio(this.periodo);
        await this.MetricaTrimestre(this.periodo,this.trimestre);
    },
    async searchTrimestre(){
        this.clsInput_Trimestre();
        await this.MetricaTrimestre(this.periodo,this.trimestre);
    },
    async handleDetalle(item){
        try {
            this.detalle=[];
            this.desde = this.periodo + '-01-01';
            this.hasta = this.periodo + '-12-30';
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/producto/'+this.contrato+'/'+item.tSku);
            const datos=response.data;
            if (datos[0].existe=='si'){
                //imagenes
                let fotos=[];
                const respuesta = await axios.get(process.env.VUE_APP_URL + '/api/inv/producto/imagen/'+this.contrato+'/'+item.tSku);
                const res= respuesta.data;
                if (res[0].existe=='si'){
                    res.forEach(element => {
                        fotos.push({src:element.Imagen});
                    });
                };
                this.detalle.push(
                    {
                        id: datos[0].ID,
                        sku: datos[0].Sku,
                        nombre: datos[0].Nombre,
                        caracteristica: datos[0].Caracteristica,
                        marca: datos[0].Marca,
                        medida: datos[0].Medida,
                        barra: datos[0].Barra,
                        stock: datos[0].Critico,
                        precioLocal: datos[0].PrecioLocal,
                        precioMayor: datos[0].PrecioMayor,
                        precioOferta: datos[0].PrecioOferta,
                        imagenes: fotos
                    }
                );
                this.modal=true;
                //chart
                this.chart=[];
                const resp = await axios.get(process.env.VUE_APP_URL + '/api/inv/v1/metrica/chart/'+this.contrato+'/'+item.tSku+'/'+this.desde+'/'+this.hasta);
                const dataChart = resp.data;
                if (dataChart[0].existe=='si'){
                    for (let index = 1; index <= 12; index++) {
                        let myVenta = 0
                        const filter = dataChart.filter( res => parseInt(res.Mes) == parseInt(index))
                        if(filter.length!=0){ myVenta = filter[0].Venta };
                        this.chart.push(myVenta)
                    }
                        
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    // async Metrica(myPeriodo,myTrimestre){
    //     this.loading=true;
    //     try {
    //         let res;
    //         //periodo
    //         this.desde = myPeriodo + '-01-01';
    //         this.hasta = myPeriodo + '-12-30';
    //         res = await axios.get(process.env.VUE_APP_URL + '/api/inv/v1/metrica/'+this.contrato+'/'+this.desde + '/' + this.hasta + '/');
    //         const datos_anio = res.data;
    //         if (datos_anio[0].existe=='si'){ 
    //             this.metrica_anio = datos_anio
    //         };
    //         //trimestres
    //         switch (myTrimestre) {
    //             case 'ENE-MAR':
    //                 this.desde = myPeriodo + '-01-01';
    //                 this.hasta = myPeriodo + '-01-30';
    //             break;
    //             case 'ABR-JUN':
    //                 this.desde = myPeriodo + '-04-01';
    //                 this.hasta = myPeriodo + '-06-30';
    //             break;
    //             case 'JUL-SEP':
    //                 this.desde = myPeriodo + '-07-01';
    //                 this.hasta = myPeriodo + '-09-30';
    //             break;
    //             case 'OCT-DIC':
    //                 this.desde = myPeriodo + '-10-01';
    //                 this.hasta = myPeriodo + '-12-30';
    //             break;
    //         };
    //         res = await axios.get(process.env.VUE_APP_URL + '/api/inv/v1/metrica/'+this.contrato+'/'+this.desde + '/' + this.hasta + '/');
    //         const datos_tri = res.data;
    //         if (datos_tri[0].existe=='si'){ 
    //             this.metrica_trimestre = datos_tri
    //         };
    //         //procesar data metrica anio
    //         let mysku_anio = '';
    //         let myproducto_anio = '';
    //         let myventas_anio = 0;
    //         let mycantidad_anio = 0;
    //         let myportal_anio='';
    //         let sumaVentas_anio = 0;
    //         let sumaCantidad_anio = 0;
    //         let sumaVentasLocal_anio = 0;
    //         let sumaVentasWeb_anio = 0;
    //         let sumaVentasEcommerce_anio = 0;
    //         let sumaCantidadLocal_anio = 0;
    //         let sumaCantidadWeb_anio = 0;
    //         let sumaCantidadEcommerce_anio = 0;
    //         if(this.metrica_anio.length!=0){
    //             this.metrica_anio.forEach(element => {                    
    //                 sumaVentas_anio = parseFloat(element.VentasLocal) + parseFloat(element.VentasWeb) + parseFloat(element.VentasEcommerce);
    //                 sumaVentasLocal_anio = parseFloat(sumaVentasLocal_anio) + parseFloat(element.VentasLocal);
    //                 sumaVentasWeb_anio = parseFloat(sumaVentasWeb_anio) + parseFloat(element.VentasWeb);
    //                 sumaVentasEcommerce_anio = parseFloat(sumaVentasEcommerce_anio) + parseFloat(element.VentasEcommerce);
    //                 sumaCantidad_anio = parseFloat(element.CantidadLocal) + parseFloat(element.CantidadWeb) + parseFloat(element.CantidadEcommerce);
    //                 sumaCantidadLocal_anio = parseFloat(sumaCantidadLocal_anio) + parseFloat(element.CantidadLocal);
    //                 sumaCantidadWeb_anio = parseFloat(sumaCantidadWeb_anio) + parseFloat(element.CantidadWeb);
    //                 sumaCantidadEcommerce_anio = parseFloat(sumaCantidadEcommerce_anio) + parseFloat(element.CantidadEcommerce);
    //                 this.itemAnio.push(
    //                     {
    //                         'tSku': element.Sku,
    //                         'tProducto': element.Producto,
    //                         'tVenta': sumaVentas_anio,
    //                         'tLocal': element.CantidadLocal,
    //                         'tWeb': element.CantidadWeb,
    //                         'tEcommerce': element.CantidadEcommerce
    //                     }
    //                 );
    //                 //mayor venta
    //                 if(parseFloat(sumaVentas_anio) > parseFloat(myventas_anio)){
    //                     mysku_anio = element.Sku;
    //                     myproducto_anio = element.Producto;
    //                     myventas_anio = parseFloat(sumaVentas_anio);
    //                     mycantidad_anio = parseFloat(sumaCantidad_anio);
    //                     if(parseFloat(element.VentasLocal) > parseFloat(element.VentasWeb) && 
    //                         parseFloat(element.VentasLocal) > parseFloat(element.VentasEcommerce)){
    //                             myportal_anio = 'Local'
    //                     }
    //                     if(parseFloat(element.VentasWeb) > parseFloat(element.VentasLocal) && 
    //                         parseFloat(element.VentasWeb) > parseFloat(element.VentasEcommerce)){
    //                             myportal_anio = 'Web'
    //                     }
    //                     if(parseFloat(element.VentasEcommerce) > parseFloat(element.VentasLocal) && 
    //                         parseFloat(element.VentasEcommerce) > parseFloat(element.VentasWeb)){
    //                             myportal_anio = 'Ecommerce'
    //                     }
    //                 }
    //             });
    //             //asignamos la mayor venta
    //             this.anio_sku = mysku_anio;
    //             this.anio_producto = myproducto_anio;
    //             this.anio_ventas = myventas_anio;
    //             this.anio_cantidad = mycantidad_anio;
    //             this.anio_portal = myportal_anio;
    //             //asignamos totales
    //             this.totalVentasLocal_anio = sumaVentasLocal_anio;
    //             this.totalVentasWeb_anio = sumaVentasWeb_anio;
    //             this.totalVentasEcommerce_anio = sumaVentasEcommerce_anio;
    //             this.totalCantidadLocal_anio = sumaCantidadLocal_anio;
    //             this.totalCantidadWeb_anio = sumaCantidadWeb_anio;
    //             this.totalCantidadEcommerce_anio = sumaCantidadEcommerce_anio
    //             //obtener porcentajes
    //             const total_anio = parseFloat(this.totalCantidadLocal_anio) + parseFloat(this.totalCantidadWeb_anio) + parseFloat(this.totalCantidadEcommerce_anio);
    //             if(parseFloat(total_anio)!=0){
    //                 const local_anio = parseFloat((parseFloat(this.totalCantidadLocal_anio) * 100) / parseFloat(total_anio)).toFixed(2);
    //                 const ecommerce_anio = parseFloat((parseFloat(this.totalCantidadEcommerce_anio) * 100) / parseFloat(total_anio)).toFixed(2);
    //                 const web_anio = parseFloat(100 - (parseFloat(local_anio) + parseFloat(ecommerce_anio))).toFixed(2);
    //                 this.porcientoLocal_anio = local_anio;
    //                 this.porcientoWeb_anio = web_anio;
    //                 this.porcientoEcommerce_anio = ecommerce_anio;
    //             }
    //         }
    //         //procesar data metrica trimestre
    //         let mysku_tri = '';
    //         let myproducto_tri = '';
    //         let myventas_tri = 0;
    //         let mycantidad_tri = 0;
    //         let myportal_tri='';
    //         let sumaVentas_tri = 0;
    //         let sumaCantidad_tri = 0;
    //         let sumaVentasLocal_tri = 0;
    //         let sumaVentasWeb_tri = 0;
    //         let sumaVentasEcommerce_tri = 0;
    //         let sumaCantidadLocal_tri = 0;
    //         let sumaCantidadWeb_tri = 0;
    //         let sumaCantidadEcommerce_tri = 0;
    //         if(this.metrica_anio.length!=0){
    //             this.metrica_trimestre.forEach(element => {
    //                 sumaVentas_tri = parseFloat(element.VentasLocal) + parseFloat(element.VentasWeb) + parseFloat(element.VentasEcommerce);
    //                 sumaVentasLocal_tri = parseFloat(sumaVentasLocal_tri) + parseFloat(element.VentasLocal);
    //                 sumaVentasWeb_tri = parseFloat(sumaVentasWeb_tri) + parseFloat(element.VentasWeb);
    //                 sumaVentasEcommerce_tri = parseFloat(sumaVentasEcommerce_tri) + parseFloat(element.VentasEcommerce);
    //                 sumaCantidad_tri = parseFloat(element.CantidadLocal) + parseFloat(element.CantidadWeb) + parseFloat(element.CantidadEcommerce);
    //                 sumaCantidadLocal_tri = parseFloat(sumaCantidadLocal_tri) + parseFloat(element.CantidadLocal);
    //                 sumaCantidadWeb_tri = parseFloat(sumaCantidadWeb_tri) + parseFloat(element.CantidadWeb);
    //                 sumaCantidadEcommerce_tri = parseFloat(sumaCantidadEcommerce_tri) + parseFloat(element.CantidadEcommerce);
    //                 this.itemTrimestre.push(
    //                     {
    //                         'tSku': element.Sku,
    //                         'tProducto': element.Producto,
    //                         'tVenta': sumaVentas_tri,
    //                         'tLocal': element.CantidadLocal,
    //                         'tWeb': element.CantidadWeb,
    //                         'tEcommerce': element.CantidadEcommerce
    //                     }
    //                 )
    //                 //mayor venta
    //                 if(parseFloat(sumaVentas_tri) > parseFloat(myventas_tri)){
    //                     mysku_tri = element.Sku;
    //                     myproducto_tri = element.Producto;
    //                     myventas_tri = parseFloat(sumaVentas_tri);
    //                     mycantidad_tri = parseFloat(sumaCantidad_tri);
    //                     if(parseFloat(element.VentasLocal) > parseFloat(element.VentasWeb) && 
    //                         parseFloat(element.VentasLocal) > parseFloat(element.VentasEcommerce)){
    //                             myportal_tri = 'Local'
    //                     }
    //                     if(parseFloat(element.VentasWeb) > parseFloat(element.VentasLocal) && 
    //                         parseFloat(element.VentasWeb) > parseFloat(element.VentasEcommerce)){
    //                             myportal_tri = 'Web'
    //                     }
    //                     if(parseFloat(element.VentasEcommerce) > parseFloat(element.VentasLocal) && 
    //                         parseFloat(element.VentasEcommerce) > parseFloat(element.VentasWeb)){
    //                             myportal_tri = 'Ecommerce'
    //                     }
    //                 }
    //             });
    //             //asignamos la mayor venta
    //             this.tri_sku = mysku_tri;
    //             this.tri_producto = myproducto_tri;
    //             this.tri_ventas = myventas_tri;
    //             this.tri_cantidad = mycantidad_tri;
    //             this.tri_portal = myportal_tri;
    //             //asignamos totales
    //             this.totalVentasLocal_tri = sumaVentasLocal_tri;
    //             this.totalVentasWeb_tri = sumaVentasWeb_tri;
    //             this.totalVentasEcommerce_tri = sumaVentasEcommerce_tri;
    //             this.totalCantidadLocal_tri = sumaCantidadLocal_tri;
    //             this.totalCantidadWeb_tri = sumaCantidadWeb_tri;
    //             this.totalCantidadEcommerce_tri = sumaCantidadEcommerce_tri
    //             //obtener porcentajes
    //             const total_tri = parseFloat(this.totalCantidadLocal_tri) + parseFloat(this.totalCantidadWeb_tri) + parseFloat(this.totalCantidadEcommerce_tri);
    //             if(parseFloat(total_tri)!=0){
    //                 const local_tri = parseFloat((parseFloat(this.totalCantidadLocal_tri) * 100) / parseFloat(total_tri)).toFixed(2);
    //                 const ecommerce_tri = parseFloat((parseFloat(this.totalCantidadEcommerce_tri) * 100) / parseFloat(total_tri)).toFixed(2);
    //                 const web_tri = parseFloat(100 - (parseFloat(local_tri) + parseFloat(ecommerce_tri))).toFixed(2);
    //                 this.porcientoLocal_tri = local_tri;
    //                 this.porcientoWeb_tri = web_tri;
    //                 this.porcientoEcommerce_tri = ecommerce_tri;
    //             }
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    //     this.loading=false;
    // },

  }
}
</script>