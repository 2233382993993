<template>
  <div>
    <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">Ecommerce</h2> <!-- Full Screen -->
    <h3 class="mt-1 hidden-sm-and-up grey--text">Ecommerce</h3> <!-- Mobile Screen --> 
    <div class="mt-5">
      <v-divider></v-divider>
      <!-- <v-bottom-navigation :value="value" color="success">
        <v-btn @click="OpenComponent(0)" :disabled="comp0">      
          <span>Orden</span>
          <v-icon>{{OrdenIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(1)" :disabled="comp1">
          <span>Conciliar</span>
          <v-icon>{{ConciliarIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(2)" :disabled="comp2">
          <span>Publicar</span>
          <v-icon>{{PublicarIcon}}</v-icon>
        </v-btn>
      </v-bottom-navigation> -->
      <div class="mt-7">
       
          <component v-bind:is="componente" :usu="usu" :contrato="contrato"></component>
      
      </div>
    </div>
    <dialogo :loading="loading"  />
  </div>
</template>

<script>
import axios from 'axios';
// import Orden from '@/components/Orden.vue';
import Orden from '@/components/Orden_Ecommerce.vue';
import Conciliar from '@/components/Conciliar.vue';
import Publicar from '@/components/Publicar.vue';
import dialogo from '@/components/dialogo.vue';
export default {
  name: 'ecommerce',
  components: { Orden, Conciliar, Publicar ,dialogo},
  props: ['usu','contrato'],
  data(){
    return{
      loading:false,
      value:0,
      componente:'',
      IconSelect:'check_circle',
      OrdenIcon:'check_circle',
      ConciliarIcon:'swap_vertical_circle',
      PublicarIcon:'public',
      comp0:true,
      comp1:true,
      comp2:true,
      fullEcommerce:false
    }
  },
  async mounted(){
    await this.entrada();
  },
  methods:{
    async entrada(){
      this.loading=true;
      const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
      const datos=response.data;
      if (datos[0].existe=='si'){
        if (datos[0].Ecommerce!=0){
            this.fullEcommerce=true
            this.comp0=false;
            this.comp1=false; // cambiar a false para Habilitar modulo
            this.comp2=false; // cambiar a false para Habilitar modulo
            this.OpenComponent(0);
        } else {
            this.fullEcommerce=false
            this.comp0=true;
            this.comp1=true;
            this.comp2=true;
        };
      } 
      this.loading=false;
    },
    OpenComponent(value){
      let vComponente;
      switch (value) {
        case 0:
          vComponente='Orden';
          this.OrdenIcon=this.IconSelect;
          this.ConciliarIcon='swap_vertical_circle';
          this.PublicarIcon='public';
          break;
        case 1:
          vComponente='Conciliar';
          this.OrdenIcon='library_books';
          this.ConciliarIcon=this.IconSelect;
          this.PublicarIcon='public';
          break;
        case 2:
          vComponente='Publicar';
          this.OrdenIcon='library_books';
          this.ConciliarIcon='swap_vertical_circle';
          this.PublicarIcon=this.IconSelect;
          break;
      }
      this.componente=vComponente;
    }
  }
}
</script>