<template>
  <div>
    <Cabecera :usu="usuario" :contrato="contrato"/>
    <!-- Autorizacion  -->
      <v-dialog v-model="enddialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Advertencia</v-card-title>
        <v-card-text>El usuario no esta Autorizado para ver este Modulo...</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="$router.go(-1)">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Fin Autorizacion -->
 </div>
</template>

<script>
import Cabecera from '@/components/Cabecera.vue';
export default {
  name: 'Home',
  components: { Cabecera },
  data(){
    return{
      enddialog:false,
      usuario:'',
      contrato:''
    }
  },
  mounted(){
   this.Autorizacion()
  },
  methods:{
    Autorizacion(){
      this.usuario = this.$route.params.idUsuario;
      this.contrato = this.$route.params.idContrato;
      console.log(this.usuario,this.contrato);
      if (this.usuario === undefined || this.usuario.trim()==''){
          this.enddialog=true;          
      }
      if (this.contrato === undefined || this.contrato.trim()==''){
          this.enddialog=true;          
      }
    }
  }
}
</script>
