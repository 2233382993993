<template>
  <div>
    <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">Venta / Devolución</h2> <!-- Full Screen -->
    <h3 class="mt-1 hidden-sm-and-up grey--text">Venta / Devolución</h3> <!-- Mobile Screen -->
    <div class="mt-5">
      <v-bottom-navigation :value="value" color="success">
        <v-btn @click="OpenComponent(0)" :disabled="comp0">
          <span>Venta</span>
          <v-icon>{{VentaIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(2)" :disabled="comp2">
          <span>Express</span>
          <v-icon>{{ExpressIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(1)" :disabled="comp1">
          <span>Devolucion</span>
          <v-icon>{{DevolucionIcon}}</v-icon>
        </v-btn>
      </v-bottom-navigation>
      <div class="mt-2">
      
          <component v-bind:is="componente" :usu="usu" :contrato="contrato"></component>
      
      </div>
    </div>
    <dialogo :loading="loading"  />
  </div>
</template>

<script>
import axios from 'axios';
import Venta from '@/components/Venta.vue';
import Devolucion from '@/components/Devolucion.vue';
import Express from '@/components/Express.vue';
import dialogo from '@/components/dialogo.vue';
export default {
  name: 'Dte',
  components: { Venta, Devolucion ,Express, dialogo},
  props: ['usu','contrato'],
  data(){
    return{
      loading:false,
      value:null,
      componente:'',
      IconSelect:'check_circle',
      VentaIcon:'monetization_on',
      DevolucionIcon:'monetization_on',
      ExpressIcon:'monetization_on',
      IconDefault:'monetization_on',
      comp0:true,
      comp1:true,
      comp2:true,
    }
  },
  async mounted(){
    await this.entrada();
  },
   methods:{
    async entrada(){
      this.loading=true;
      const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
      const datos=response.data;
      if (datos[0].existe=='si'){
        if(datos[0].Permiso_3_2!=0) {
          this.comp0=false;
        };
        if(datos[0].Permiso_3_4!=0) {
          this.comp1=false;
        };
        if(datos[0].Permiso_3_5!=0) {
          this.comp2=false;
        };
      } 
      this.moduloActivo();      
    },
    moduloActivo(){
        if (this.comp0!=true){
          this.componente='Venta';
          this.VentaIcon=this.IconSelect;
          this.DevolucionIcon=this.IconDefault;
          this.ExpressIcon=this.IconDefault;
          this.value=0
        } 
        else {
          if (this.comp1!=true){
            this.componente='Devolucion';
            this.VentaIcon=this.IconDefault;
            this.DevolucionIcon=this.IconSelect;
            this.ExpressIcon=this.IconDefault;
            this.value=1
          } else {
            if (this.comp2!=true){
              this.componente='Express';
              this.VentaIcon=this.IconDefault;
              this.DevolucionIcon=this.IconSelect;
              this.ExpressIcon=this.IconSelect;
              this.value=2
            } else {
              this.VentaIcon=this.IconDefault;
              this.DevolucionIcon=this.IconDefault;
              this.ExpressIcon=this.IconDefault;
            }
          }
        }
        this.loading=false;
    },
    OpenComponent(value){
      let vComponente;
      switch (value) {
        case 0:
          vComponente='Venta';
          this.VentaIcon=this.IconSelect;
          this.DevolucionIcon=this.IconDefault;
           this.ExpressIcon=this.IconDefault;
        break;
        case 1:
          vComponente='Devolucion';
          this.VentaIcon=this.IconDefault;
          this.DevolucionIcon=this.IconSelect;
          this.ExpressIcon=this.IconDefault;
        break;
        case 2:
          vComponente='Express';
          this.VentaIcon=this.IconDefault;
          this.DevolucionIcon=this.IconDefault;
          this.ExpressIcon=this.IconSelect;
        break;
      }
      this.componente=vComponente;
    }
  }
}
</script>